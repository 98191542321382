import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AnalyticsInfo } from "./analytics-info";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  @Injectable({
    providedIn: 'root'
  })
export class AnalyticsService {

    analyticsUrl = environment.apiUrl + 'analytics';

    constructor(private http: HttpClient) {
    }

    post(info: AnalyticsInfo): Observable<string> {
        return this.http.post<string>(this.analyticsUrl, info, httpOptions)
    }
    get(): Observable<boolean> {
      return this.http.get<boolean>(this.analyticsUrl, httpOptions);
    }

}