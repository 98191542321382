export class Tipp {
  id: number;
  fullmatch: boolean;
  title: string;
  introduction: string;
  shortdescription: string;
  backgroundinfo: string;
  howitworks: string;
  examples: string;
  tryit: string;
  moreinformation: string;
  inputfield: boolean;
  verb: string;
  noun: string;
}

export class Kompetenz {
  id: number;
  wort: String;
}

export class Tippinputlog {
  text: String;
  filterclass: String;
  filtercompetence: String;
  userchoice: String;
}

export class TippLang {
  id: number;
  fullmatch: boolean;
  title: string;
  title_en: string;
  introduction: string;
  shortdescription: string;
  backgroundinfo: string;
  howitworks: string;
  examples: string;
  tryit: string;
  moreinformation: string;
  introduction_en: string;
  shortdescription_en: string;
  backgroundinfo_en: string;
  howitworks_en: string;
  examples_en: string;
  tryit_en: string;
  moreinformation_en: string;
  inputfield: boolean;
  verb: string;
  noun: string;
}
