import { PopupDto } from './../popup-widget/popupDto';
import { ProgressService } from './../progress/progress.service';
import { JournalEntry } from './../journal/journal-entry';
import { PopupWidgetComponent } from './../popup-widget/popup-widget.component';
import { PathService } from '../messenger/path.service';
import { Component, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { JournalService } from '../journal/journal-service.service';
import { JournalDataService } from '../journal/journal.data.service';
import { environment } from './../../environments/environment';
import { TippLang } from '../messenger/tipp';
import { HintDataService } from '../messenger/hint.data.service';
import { TokenStorageService } from '../auth/token-storage.service';
import { HtmlService } from '../html.service';
import { htmlDataService } from '../html.data.service';
import { JournalEntryDetailsText } from './JournalEntryDetailsText';
import { JournalEntryDto } from '../journal/journalEntryDto';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ProgressDataService } from '../progress/progress.data.service';
import { JournalComponent } from '../journal/journal.component';
import {TranslateService} from '@ngx-translate/core';
import { SecurityService } from '../auth/security-service';

@Component({
  selector: 'app-journal-element',
  templateUrl: './journal-element.component.html',
  styleUrls: ['./journal-element.component.scss']
})
export class JournalElementComponent implements OnInit {
  pdf: any;
  tipps: TippLang[];
  info: any;
  sorted = false;
  titleactive = false;
  startEvaluation = false;
  notempty = true;
  journalEntry: JournalEntry;
  like = false;
  dislike = false;
  newest: JournalEntryDto;
  popupDto: PopupDto;
  i: number = 0;
  journalTypSolution: string;

  /* moved to journal.ts
  public downloadPDF() {
    window.open(environment.apiUrl + 'pdf', '_blank');
  }*/

  public downloadOnePDF(requestedid) {
    if(this.token.getToken() != null){
    window.open(environment.apiUrl + 'pdf/' + requestedid + '?token=' + this.token.getToken(), '_blank');
    } else {
      window.open(environment.apiUrl + 'pdf/' + requestedid + '?token=' + this.token.getSessionToken(), '_blank');
    }
  }

  constructor(
    private journalComponent: JournalComponent,
    private token: TokenStorageService,
    public journalservice: JournalService,
    private progressService: ProgressService,
    private progressdataservice: ProgressDataService,
    public pathservice: PathService,
    public journaldataservice: JournalDataService,
    private tippService: HintDataService,
    private htmlService: HtmlService,
    private htmldataservice: htmlDataService,
    public dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private securityService: SecurityService
  ) { }

  ngOnInit() {
    this.pathservice.propagate = false;
    //this.journalTypSolution = this.translate.instant('sklls.journal.solution');
    this.resetPositivNegativ();
    this.htmldataservice.getVocabulary().subscribe(data => {
      this.htmlService.vocabulary = data;
      this.htmlService;
      this.tippService.getAllTipps().subscribe(data => {
        this.tipps = data;
      });
      this.info = {
        token: this.token.getToken() || this.token.getSessionToken(),
        fullname: this.token.getUsername() || this.token.getSessionUsername(),
        auditModel: this.securityService.hasAuditModel()
      };
    });
  }

  logout() {
    this.token.signOut();
    window.location.reload();
  }

  openDialog() {
    const dialogRef = this.dialog.open(PopupWidgetComponent, {
      data: {
        title: this.popupDto.title, levelTitle: this.popupDto.levelTitle, bildPopup: this.popupDto.bildPopup, bildLevel: this.popupDto.bildLevel, text: this.popupDto.textPopup,
        textWidget: this.popupDto.textwidget, buttonJa: this.popupDto.buttonA, buttonNein: this.popupDto.buttonB, progressPoints: this.popupDto.progressPoints
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.startEvaluation = result;
      if (this.startEvaluation) {
        if (this.like) {
          this.startEvaluationPositivCoaching();
          this.resetPositivNegativ();
        }
        if (this.dislike) {
          this.startEvaluationNegativCoaching();
          this.resetPositivNegativ();
        }
      }
      if (!this.startEvaluation) {
        this.ngOnInit();
      }
    });
  }

  changeFavStatus(journalentry: JournalEntry) {
    journalentry.favorite = this.journalservice.invertBoolean(
      journalentry.favorite
    );
    this.journalservice.updateJournalEntries(journalentry);
  }

  likeCoachingPath(journalentry: JournalEntry) {
    this.isPositiv();
    journalentry.like = true;
    this.setJournalEntry(journalentry);

    this.journaldataservice.updateJournal(journalentry).subscribe(data => {
      this.progressdataservice.addProgress(1).subscribe(data => {
        this.popupDto = data;
        this.openDialog();
      });
    });

    // only change the "like" button color of challeng and celebrate
   /*  const challengeCelebrateId = [24, 25, 31, 30, 35, 15, 1, 27, 28, 29];
    const id = journalentry.id; */

/*     if (!challengeCelebrateId.includes(journalentry.coachingpathid)) {
      this.journaldataservice.updateJournal(journalentry).subscribe(data => {
        this.progressdataservice.addProgress(1).subscribe(data => {
          this.popupDto = data;
          this.openDialog();
        });
      });
    } else {
      this.journaldataservice.updateJournal(journalentry).subscribe(data => {
        this.progressdataservice.addProgress(1).subscribe(data => {
        this.popupDto = data;
        for (let dto = 0; dto < this.journalservice.journalentriesDto.length; dto ++){
          if (this.journalservice.journalentriesDto[dto].journalEntry.id === id){
            this.journalservice.journalentriesDto[dto].like = true;
            this.journalservice.journalentriesDto[dto].dislike = false;
          }
        }
      });
    });
    } */

  }

  startEvaluationPositivCoaching() {
    this.pathservice.router.navigateByUrl('/main/path');
    this.pathservice.resetStepNo();
    this.pathservice.setJournalEntry(this.journalEntry);
    this.pathservice.setPathTypeEvaluatePos();
    this.pathservice.setPathTitle('');
  }

  dislikeCoachingPath(journalentry: JournalEntry) {
    this.isNegativ();
    journalentry.like = false;
    this.setJournalEntry(journalentry);

    this.journaldataservice.updateJournal(journalentry).subscribe(data => {
      this.progressdataservice.addProgress(1).subscribe(data => { //addProgress(1): beteutet  addAppliedSolutionsToProgress()
        this.popupDto = data;
        this.openDialog();
      });
    });

    // only change the "dislike" button color of challeng and celebrate
    /* const challengeCelebrateId = [24, 25, 31, 30, 35, 15, 1, 27, 28, 29];
    const id = journalentry.id;

    if (!challengeCelebrateId.includes(journalentry.coachingpathid)) {
      this.journaldataservice.updateJournal(journalentry).subscribe(data => {
        this.progressdataservice.addProgress(1).subscribe(data => { //addProgress(1): beteutet  addAppliedSolutionsToProgress()
          this.popupDto = data;
          this.openDialog();
        });
      });
    } else {
      this.journaldataservice.updateJournal(journalentry).subscribe(data => {
        this.progressdataservice.addProgress(1).subscribe(data => { //addProgress(1): beteutet  addAppliedSolutionsToProgress()
        this.popupDto = data;
        for (let dto = 0; dto < this.journalservice.journalentriesDto.length; dto ++){
          if (this.journalservice.journalentriesDto[dto].journalEntry.id === id){
            this.journalservice.journalentriesDto[dto].dislike = true;
            this.journalservice.journalentriesDto[dto].like = false;
          }
        }
      });
    });
    } */
  }

  startEvaluationNegativCoaching() {
    this.pathservice.router.navigateByUrl('/main/path');
    this.pathservice.resetStepNo();
    this.pathservice.setJournalEntry(this.journalEntry);
    this.pathservice.setPathTypeEvaluateNeg();
    this.pathservice.setPathTitle('');
  }

  setJournalEntry(journalentry: JournalEntry) {
    this.journalEntry = journalentry;
  }

  isPositiv() {
    this.like = true;
  }

  isNegativ() {
    this.dislike = true;
  }

  resetPositivNegativ() {
    this.like = false;
    this.dislike = false;
  }

  changeCompletedStatus(journalEntryDto: JournalEntryDto) {
    let journalEntry = journalEntryDto.journalEntry;
    journalEntry.completed = true;
    this.journalservice.updateJournalEntries(journalEntry);

    if (journalEntryDto.pathType === 11) {
      this.progressService.addPassedChallengesToProgress();
      setTimeout(() => this.journalComponent.openDialog(), 1500);
    }

    if (journalEntryDto.pathType === 1 || journalEntryDto.pathType === 2) {
      this.progressService.addCoachedTasksToProgress();
    }
  }

  // Nimmt den JournalDetail Inhalt und trennt Titel und Text
  journalTextTitle(journalEntryDetailsElementText: string) {
    const journalEntryDetailsTexte = new Array<JournalEntryDetailsText>();
    /*  console.log('journal Text: ', journalEntryDetailsElementText); */
    let journaltext: string[];
    let part: string[];
    let titel: string;
    let text: string;
    if (journalEntryDetailsElementText !== null) {
      journalEntryDetailsElementText = journalEntryDetailsElementText.replace('</li><br> <li>','</li><li>')
      journalEntryDetailsElementText = this.getJournalEntryDetailsElementText(journalEntryDetailsElementText);
      journaltext = journalEntryDetailsElementText.split('</headline>');
      if (
        journaltext.length % 2 !== 0 ||
        journaltext[0].trim().charAt(0) !== '<'
      ) {
        for (let i = 0; i < journaltext.length; i++) {
          part = journaltext[i].split('<headline>');
          text = this.getText(titel, part[0]);
          journalEntryDetailsTexte.push({ titel, text });
          titel = null;
          text = null;
          titel = part[1];
        }
      } else if (
        journaltext.length % 2 === 0 &&
        journaltext[0].trim().charAt(0) === '<'
      ) {
        for (let i = 0; i < journaltext.length; i++) {
          part = journaltext[i].split('<headline>');
          if (i === 0) {
            titel = part[1];
            journalEntryDetailsTexte.push({ titel, text });
            titel = null;
            text = part[0];
            journalEntryDetailsTexte.push({ titel, text });
          } else {
            text = part[0];
            journalEntryDetailsTexte.push({ titel, text });
            titel = null;
            text = null;
            titel = part[1];
          }
        }
      }
    }
    return journalEntryDetailsTexte;
  }

  getText(title: string, text: string): string {
    let result = text;

    if (title !== null && title !== undefined && title === this.translate.instant('sklls.journal.headlinechallenge')) {
      result= result.replace('Wenn <li>', 'Wenn ');
      result = result.replace('</li> in', ' in');
      result = result.replace('war.§<li>', 'war.');
      result= result.replace('</li> gibt mir', ' gibt mir');
    }

    return result;
  }

  getJournalEntryDetailsElementText(journalEntryDetailsElementText: string): string {
    let result = journalEntryDetailsElementText;
    if (result.match(/<li>/g) !== null){
    const countNumberOfLiTags = result.match(/<li>/g).length;

    if (countNumberOfLiTags > 0 && result.includes('<ul>') && result.includes(this.translate.instant('sklls.journal.headlineevaluateanalyze'))) {
      const startIndex = result.search('<ul><li>');
      const endIndex = result.search('</li></ul>');

      result = result.substring(startIndex, endIndex);

      const listResult = result.split('</li><li>');
      listResult[0] = listResult[0].replace('<ul><li>', '');

      for (let i = 0; i < listResult.length; i++) {
        const element = listResult[i];
        const startIndex1 = element.search('=>');
        listResult[i] = element.substring(startIndex1, element.length);
        listResult[i] = '<li>' + listResult[i].replace('=>', '') + '</li>';
      }

      const newContent = '<ul>' + listResult.toString().replace(/,/g, '') + '</ul>';

      journalEntryDetailsElementText = this.replaceFromIndexToIndex(startIndex, endIndex, journalEntryDetailsElementText, newContent);
    }}

    return journalEntryDetailsElementText;
  }
  replaceFromIndexToIndex(startIndex: number, endIndex: number, journalEntryDetailsElementText: string, newContent: string): string {
    return journalEntryDetailsElementText.substring(0, startIndex) + newContent + journalEntryDetailsElementText.substring(endIndex);
  }

  // Makes Details of type "Meine Notizen" appear at the end of each JournalEntryDetails list.
  sortdetails() {
    if (!this.sorted) {
      this.sorted = true;
      this.journalservice.journalentrydetailselements.sort((n1, n2) => {
        if (n1.typ === 'Meine Notizen' || n1.typ === 'My notes') {
          return 1;
        }
        if (n2.typ === 'Meine Notizen' || n2.typ === 'My notes') {
          return -1;
        } else {
          return 0;
        }
      });
    }
  }
  toMain() {
    this.router.navigateByUrl('/main/journey');
  }
  // Handels Propagation after Popup Dialog.
  checkNewest(someElement: JournalEntryDto) {
    if (this.pathservice.pathType == this.pathservice.evaluatePos || this.pathservice.pathType == this.pathservice.evaluateNeg) {
      var res1 = Math.max.apply(Math, this.journalservice.journalentrydetailselements.map(function (o) { return o.id; }));
      var res2 = this.journalservice.journalentrydetailselements.find(function (o) { return o.id == res1 });
      this.newest = this.journalservice.journalentriesDto.find(function (dto) { return (dto.journalEntry.id === res2.journalEntryId.id) });
    }
    else {
      var res = Math.max.apply(Math, this.journalservice.journalentriesDto.map(function (o) { return o.journalEntry.id; }));
      this.newest = this.journalservice.journalentriesDto.find(function (o) { return o.journalEntry.id == res });
    }
    this.sorted = false;
    this.sortdetails();
    if (someElement == this.newest && this.pathservice.propagate) {
      return true;
    }

    else {
      return false;
    }


  }

  formatTippText(text: string, journalEntry: JournalEntry) {
    if (text != null) {
      var text1 = this.htmlService.replaceAll(this.htmlService.replaceAll(text, "|", "<br><br>"), "[Titel der Aufgabe]", (journalEntry.title).substring(0, (journalEntry.title.length - 1)));
      return this.htmlService.convertToHtml(text1);
    } else {
      return text;
    }
  }

  formatAdditionalTipp(text: string, position: number): string {
    if (text === undefined || text === null) {
      text = '';
      console.log("WARN: text null/undefined (journal-element.component.ts:407)");
    }
    var text1 = text.split("<br><br>")[position];

    if (text1 === 'undefined') {
      text1 = '';
    }

    return this.htmlService.convertToHtml(text1);
  }

  trimTitleFromIntroduciton(introduction: string) {
    let trim = introduction.split('§§');
    if (trim.length > 1) {
      return trim[1];
    } else {
      return introduction;
    }
  }


}
