import { HTTP_INTERCEPTORS, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { TokenStorageService } from './token-storage.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SidenavMenuComponent } from '../sidenav-menu/sidenav-menu.component';
import { SecurityService } from './security-service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private token: TokenStorageService,
                private sidenav: SidenavMenuComponent,
                private securityService: SecurityService) {}

    intercept(req: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>> {
        let authReq = req;
        const token = this.token.getToken() || this.token.getSessionToken();
        if (token != null) {
            authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        }
        return next.handle(authReq).pipe(
		  tap({
            next: (event) => {
              if (event instanceof HttpResponse && token != null && event.headers.get('Authorization') != null) {
                let newToken = event.headers.get('Authorization').replace('Bearer ', '');
                if (token != newToken) {
                  if (this.token.getToken() != null) { 
                    this.token.saveToken(newToken);
                  } else {
                    this.token.saveSessionToken(newToken);
                  }
                  this.sidenav.toggleAskSally(this.securityService.isUnternehmenAllowsGptUsage());
                }
              }
              return event;
            }
		  })
		);
    }
}

export const HttpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];