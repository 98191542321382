import { Injectable } from '@angular/core';
import { MenuPoint } from './sidenav-menu/menuPoint';
import { Router } from '@angular/router';
import { CustomTranslationLoader } from "./translate/translate.service";
import { TokenStorageService } from "./auth/token-storage.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class AppService {
  menuPoint: MenuPoint;
  t: boolean;
  title: string;
  public selectedLanguage: string;
  header: string;

  constructor(public router: Router,
    private translate: TranslateService,
    private tokenStorage: TokenStorageService,
    private customTranslationLoader: CustomTranslationLoader) {}

  getHeaderTitle() {
    if(this.router.url=='/main/journal') {
        this.header = this.translate.instant('sklls.sidenav.journal');
        return this.header;

        }
      if(this.router.url== "/main" || this.router.url =="/"){
        this.header = this.translate.instant('sklls.sidenav.coaching');
        return this.header;

      }
      if(this.router.url== '/main/fortschritt'){
        this.header = this.translate.instant('sklls.sidenav.progress');
        return this.header;

      }
      if(this.router.url== '/main/resilience-radar'){
        this.header = this.translate.instant('sklls.sidenav.resilience');
        return this.header;

      }
      if(this.router.url== '/main/journey'){
        this.header = this.translate.instant('sklls.sidenav.journey');
        return this.header;

      }
      if(this.router.url== '/main/challenges'){
        this.header = 'Challenges';
        return this.header;

      }
      if(this.router.url== '/main/editor'){
        this.header = 'Editor';
        return this.header;

      }
      if(this.router.url=='/main/fragebogen'){
        this.header = 'Fragebogen';
        return this.header;

      }
      if(this.router.url=='/error'){
        this.header = '404: Seite nicht gefunden';
        return this.header;

      }
      /*else{
        this.title= 'Ups, da ist was schief gelaufen. Bitte lade die Seite erneut.'
        return this.title;

      }*/

  }
  setHeaderTitle(title: string) {
    this.title = title;
  }

  getLanguage() {
    this.customTranslationLoader.loading = true;
    if (this.tokenStorage.getLanguange() || this.tokenStorage.getSessionLanguange() != null) {
      this.selectedLanguage = this.tokenStorage.getLanguange() || this.tokenStorage.getSessionLanguange();
      this.translate.use(this.selectedLanguage);
      this.customTranslationLoader.loading = false;
    } else {
      this.customTranslationLoader.getAcceptLanguage().subscribe((data) => {
        this.selectedLanguage = data;
        this.tokenStorage.setLanguange(this.selectedLanguage);
        this.tokenStorage.setSessionLanguange(this.selectedLanguage);
        this.translate.use(this.selectedLanguage);
        this.customTranslationLoader.loading = false;
      });
    }
  }

  changeLanguage(lang) {
    this.selectedLanguage = lang;
    this.translate.use(this.selectedLanguage);
  }

}
