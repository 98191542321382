export class Progress {
  coachedtasks: number;
  usedsolutions: number;
  evaluatedsolutions: number;
  passedchallenges: number;
  createdjournalentries: number;
  accountid: object;
  id: number;
}

export class Progressbar {
  //value: number;
  color = '#00778b';
  mode = 'determinate';
  bufferValue = 100;

/*   constructor () {
    this.value = 0;
  } */
}


