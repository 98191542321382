import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Tutorial } from "./tutorial";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" })
};

@Injectable({
  providedIn: "root"
})
export class TutorialDataService {
  private apiUrl = environment.apiUrl + "tutorial/";
  httpClient: HttpClient;

  constructor(private http: HttpClient) {
    this.httpClient = http;
  }

  getTutorialById(tutorialId: number): Observable<Tutorial> {
    return this.http.get<Tutorial>(this.apiUrl + tutorialId);
  }

  getTutorialList(): Observable<Tutorial[]> {
    return this.http.get<Tutorial[]>(this.apiUrl);
  }
}
