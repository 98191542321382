import { Component, OnInit } from '@angular/core';
import { PathService } from '../messenger/path.service';
import { TokenStorageService } from '../auth/token-storage.service';
import { ChallengesElement } from '../challenge/challengesElement';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { SecurityService } from '../auth/security-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  ENV = environment;
  info: any;
  challengesElement: ChallengesElement;

  constructor(
    private token: TokenStorageService,
    public pathservice: PathService,
    private appService: AppService,
    private router: Router, 
    private securityService: SecurityService
  ) {}

  ngOnInit() {
    this.info = {
      token: this.token.getToken() || this.token.getSessionToken(),
      fullname: this.token.getUsername() || this.token.getSessionUsername(),
      isUnternehmenAllowsGptUsage: this.securityService.isUnternehmenAllowsGptUsage()
    };
    if(this.info.isUnternehmenAllowsGptUsage === false) {
      this.router.navigateByUrl('/main/journey');
    }
  }

  logout() {
    this.token.signOut();
    window.location.reload();
  }

  startPath(typeId: number, titel: string) {
    this.pathservice.resetStepNo();
    this.pathservice.resetPathId();
    this.pathservice.setPathType(typeId);
    this.pathservice.setPathTitle(titel);
  }
}
