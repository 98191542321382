import {UserService} from '../services/user.service';
import {AuthLoginInfo} from '../auth/login-info';
import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {TokenStorageService} from '../auth/token-storage.service';
import {PathService} from '../messenger/path.service';
import {environment} from 'src/environments/environment';
import {CustomTranslationLoader} from '../translate/translate.service';
import {AppService} from '../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SecurityService} from '../auth/security-service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  ENV = environment;
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  privacyAccepted = false;
  email: string;
  errorMessage = "";
  private loginInfo: AuthLoginInfo;
  tutorial: number;

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private pathservice: PathService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public customTranslationLoader: CustomTranslationLoader,
    private appService: AppService,
    private userService: UserService,
    private securityService: SecurityService
  ) {this.form.rememberMe = true;}

  startPath(id: number, titel: string, type: number) {
    this.pathservice.resetStepNo();
    this.pathservice.setPathId(id);
    this.pathservice.setPathType(type);
    this.pathservice.setPathTitle(titel);
    this.form.rememberMe = true;
  }

  ngOnInit() {
    if(this.tokenStorage.getToken() || this.tokenStorage.getSessionToken()) {
      this.activatedRoute.queryParams.subscribe(params => {
        let coachingpathid = params['coachingpathid'];
        let url = this.router.url;
        if(this.securityService.hasUserRole() && coachingpathid != null && url.match(coachingpathid)) {
          this.router.navigateByUrl('/main/path?coachingpathid=' + coachingpathid);
        } else if(this.securityService.hasUserRole() && this.securityService.hasAdminRole() && coachingpathid != null && url.match(coachingpathid)) {
          this.router.navigateByUrl('/main/path?coachingpathid=' + coachingpathid);
        } else if(this.securityService.hasUserRole()) {
          this.router.navigateByUrl('/main/journey');
        } else if(this.securityService.hasAdminRole()) {
          this.router.navigateByUrl('/analytics');
        }  else if(this.securityService.hasUserRole() && this.securityService.hasAdminRole()) {
            this.router.navigateByUrl('/main/journey');
        } 
    })
      this.isLoggedIn = true;
    }
  }

  onSubmit() {
    console.log(this.form);
    this.loginInfo = new AuthLoginInfo(this.form.email, this.form.password, this.form.rememberMe);
    this.authService.attemptAuth(this.loginInfo).subscribe(
      data => {
        if(this.form.rememberMe == true) {
        this.tokenStorage.saveToken(data.accessToken);
        //this.tokenStorage.saveUsername(data.accountfullname);
        this.tokenStorage.saveUsername(data.fullname);
        this.tokenStorage.saveStartDateAndTime(data.startDateAndTime);
        this.tokenStorage.saveEndDateAndTime(data.endDateAndTime);
        this.tokenStorage.saveEmail(this.loginInfo.email);
        this.tokenStorage.setAccountUUID(data.accountExternalId);
        this.tokenStorage.setUnternehmentUUID(data.unternehmenExternalId);
      } else {
        this.tokenStorage.saveSessionToken(data.accessToken);
        this.tokenStorage.saveSessionUsername(data.fullname);
        this.tokenStorage.saveSessionStartDateAndTime(data.startDateAndTime);
        this.tokenStorage.saveSessionEndDateAndTime(data.endDateAndTime);
        this.tokenStorage.saveSessionEmail(this.loginInfo.email);
        this.tokenStorage.setSessionAccountUUID(data.accountExternalId);
        this.tokenStorage.setSessionUnternehmenUUID(data.unternehmenExternalId);
      }
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.userService.getUserLanguage().subscribe((data) => {
            if (data === "") {
              this.userService.setUserLanguage(this.tokenStorage.getLanguange() || this.tokenStorage.getSessionLanguange()).subscribe(data =>{
                //this.appService.selectedLanguage = data;
                this.reloadPage();
              });

            } else{
              this.reloadPage();
            }
          });
        },
      error => {
        console.log(error);
        this.errorMessage = error.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  onChangeLanguage(lang) {
    this.tokenStorage.setLanguange(lang);
    this.tokenStorage.setSessionLanguange(lang);
    this.appService.changeLanguage(lang);
  }

  reloadPage() {

    this.activatedRoute.queryParams.subscribe(params => {
      let coachingpathid = params['coachingpathid'];
      let url = this.router.url;
      if(this.isLoggedIn == true && this.securityService.hasUserRole() && coachingpathid != null && url.match(coachingpathid)) {
        this.router.navigateByUrl('/main/path?coachingpathid=' + coachingpathid);
      } else if(this.isLoggedIn == true && this.securityService.hasUserRole() && this.securityService.hasAdminRole() && coachingpathid != null && url.match(coachingpathid)) {
        this.router.navigateByUrl('/main/path?coachingpathid=' + coachingpathid);
      } else if(this.isLoggedIn == true && this.securityService.hasUserRole()) {
        this.router.navigateByUrl('/main/journey');
      } else if(this.isLoggedIn == true && this.securityService.hasAdminRole()) {
        this.router.navigateByUrl('/analytics');
      }  else if(this.isLoggedIn == true && this.securityService.hasUserRole() && this.securityService.hasAdminRole()) {
          this.router.navigateByUrl('/main/journey');
      } else if(this.isLoggedIn == true) {
        window.location.reload();
        return true;
      }
  })
 }
}
