import { Component, OnInit, AfterViewInit } from '@angular/core';
import { JournalService } from './journal-service.service';
import { MatDialog } from '@angular/material';
import { PathService } from '../messenger/path.service';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';
import { PopupDto } from './../popup-widget/popupDto';
import { ProgressService } from '../progress/progress.service';
import { environment } from './../../environments/environment';
import { TokenStorageService } from '../auth/token-storage.service';
import { AppService } from '../app.service';
import { SecurityService } from '../auth/security-service';

@Component({
  selector: 'app-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.scss']
})
export class JournalComponent implements OnInit, AfterViewInit {
  ENV = environment;
  public selection: any;
  showdialog2: boolean;
  alphabeticalArrow = 'none';
  dateArrow = 'none';
  showOnlyFavorites = false;
  show_favorite = false;
  searchTerm = '';
  popupDto: PopupDto;
  info: any;
  
  constructor(
    private pathservice: PathService,
    public dialog: MatDialog,
    private journalservice: JournalService,
    private progressService: ProgressService,
    private token: TokenStorageService,
    private appService: AppService,
    private securityService: SecurityService
  ) {}

  ngOnInit() {
    this.journalservice.getJournalEntries();
    this.journalservice.getJournalDetails();
    this.showdialog2 = this.progressService.showdialog;
    this.info = {  
      auditModel: this.securityService.hasAuditModel(),
    }

  }

  ngAfterViewInit() {
    if (this.showdialog2) {
      setTimeout(() => this.openDialog(), 1500);
    }
  }

  public downloadPDF() {
    if(this.token.getToken() != null) {
    window.open(environment.apiUrl + 'pdf' + '?token=' + this.token.getToken(), '_blank');
    } else {
      window.open(environment.apiUrl + 'pdf' + '?token=' + this.token.getSessionToken(), '_blank');
    }
  }

  // Verursacht, dass immer nur der Pfeil auf dem aktivierten Sortierknopf angezeigt wird
  manageIconArrow(iconPurpose: String) {
    if (iconPurpose === 'alphabeticalArrow') {
      if (this.alphabeticalArrow === 'none') {
        this.alphabeticalArrow = 'arrow_upward';
        this.dateArrow = 'none';
      } else {
        this.alphabeticalArrow = this.changeIconArrow(this.alphabeticalArrow);
      }
    } else {
      if (this.dateArrow === 'none') {
        this.dateArrow = 'arrow_upward';
        this.alphabeticalArrow = 'none';
      } else {
        this.dateArrow = this.changeIconArrow(this.dateArrow);
      }
    }
  }

  // Wechselt Pfeilrichtung
  changeIconArrow(iconArrow: string) {
    if (iconArrow === 'arrow_upward') {
      iconArrow = 'arrow_downward';
    } else {
      iconArrow = 'arrow_upward';
    }
    return iconArrow;
  }

  // Sortiert Einträge nach Datum/Alphabet auf- und absteigend
  sortJournalEntries(alphabetical: boolean, direction: string) {
    if (direction === 'alphabeticalArrow') {
      direction = this.alphabeticalArrow;
    } else {
      direction = this.dateArrow;
    }
    this.journalservice.journalentriesDto.sort((n1, n2) => {
      if (alphabetical) {
        const x = n1.journalEntry.title.toLowerCase();
        const y = n2.journalEntry.title.toLowerCase();
        if (direction === 'arrow_upward') {
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        } else {
          if (x > y) {
            return -1;
          }
          if (x < y) {
            return 1;
          }
          return 0;
        }
      } else {
        if (direction === 'arrow_upward') {
          if (n1.journalEntry.createdat > n2.journalEntry.createdat) {
            return 1;
          }
          if (n1.journalEntry.createdat < n2.journalEntry.createdat) {
            return -1;
          }
          return 0;
        } else {
          if (n1.journalEntry.createdat > n2.journalEntry.createdat) {
            return -1;
          }
          if (n1.journalEntry.createdat < n2.journalEntry.createdat) {
            return 1;
          }
          return 0;
        }
      }
    });
  }

  // Filtert die Liste nach Suchterm
  search() {
    this.filter();
  }

  // Legt den Favoritenfilter fest
  toggleFavoriteFilter() {
    this.showOnlyFavorites = this.journalservice.invertBoolean(
      this.showOnlyFavorites
    );
    this.journalservice.setFavoriteFilter(this.showOnlyFavorites);
    this.filter();
  }

  filter() {
    let retval = this.journalservice.journalentriesall;
    if (this.showOnlyFavorites) {
      // nur favorite-filter
      retval = retval.filter(JournalEntry => {
        return JournalEntry.journalEntry.favorite === true;
      });
    }
    if (this.searchTerm.trim().length > 0) {
      // nur suche-filter
      retval = retval.filter(JournalEntry => {
        return JournalEntry.journalEntry.title
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase());
      });
    }
    this.journalservice.journalentriesDto = retval;
  }

  openDialog() {
    this.popupDto= this.progressService.popupDto;
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      disableClose: true,
      data: { hallo: this.popupDto.title, 
        levelTitle: this.popupDto.levelTitle,
        bild: this.popupDto.bildLevel, 
        bild2: this.popupDto.bildPopup,
        text: this.popupDto.textPopup, 
        buttonJa: this.popupDto.buttonA,
        progressPoints: this.popupDto.progressPoints
        
      }});

    dialogRef.afterClosed().subscribe( () => {

      this.progressService.showdialog= false;
      this.pathservice.propagate = true;
    });
    
    }
}
