import { AppRoutingModule } from "./app-routing.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { SidenavMenuComponent } from "./sidenav-menu/sidenav-menu.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ChallengeComponent } from "./challenge/challenge.component";
import { FooterComponent } from "./footer/footer.component";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  MatButtonModule,
  MatToolbarModule,
  MatSidenavModule,
  MatMenuModule,
  MatIconModule,
  MatButtonToggleModule,
  MatFormFieldModule,
  MatInputModule,
  MatExpansionModule,
  MatCardModule,
  MatGridListModule,
  MatListModule,
  MatProgressBarModule,
  MatDialogModule,
  MatRadioModule,
  MatCheckboxModule,
  MatTabsModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatStepperModule
} from "@angular/material";
import { JournalComponent } from "./journal/journal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JournalElementComponent } from "./journal-element/journal-element.component";
import { JournalEntryEditorComponent } from "./journal-entry-editor/journal-entry-editor.component";
import { ProgressComponent } from "./progress/progress.component";
import { HelpDialogComponent } from "./help-dialog/help-dialog.component";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { HttpClientModule, HttpClient} from "@angular/common/http";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { MyOverlayComponent } from "./my-overlay/my-overlay.components";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./resetPassword/resetPassword.component";
import { ResetPasswordTokenComponent } from "./resetPasswordToken/resetPasswordToken.component";
import { MainComponent } from "./main/main.component";
import { LoginlayoutComponent } from "./loginlayout/loginlayout.component";
import { AdminComponent } from "./admin/admin.component";
import { UserComponent } from "./user/user.component";
import { RegisterComponent } from "./register/register.component";
import { HttpInterceptorProviders } from "./auth/auth-interceptor";
import { AuthGuard } from "./auth/auth.guard";
import { PopupDialogComponent } from "./popup-dialog/popup-dialog.component";
import { QuillModule } from "ngx-quill";
import { JoyrideModule } from "ngx-joyride";
import { RouterModule } from "@angular/router";
import { PopupWidgetComponent } from "./popup-widget/popup-widget.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faWrench, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { OnboardingDirective } from "./onboarding.directive";
import { EscapeHtmlPipe } from "./helper/keep-html.pipe";
import { ErrorInterceptorProviders } from "./error";
import { TutorialDialog } from "./tutorial/tutorial.dialog.componenten";
import { ConfirmDialog } from "./confirm-dialog/confirm-dialog";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CustomTranslationLoader } from './translate/translate.service';
import { AnalyticsComponent } from './analytics/analytics.component';
import { JourneyComponent } from './journey/journey.component';
import { MessengerComponent } from './messenger/messenger.component';
import { ResilienceTestComponent } from './resilience-test/resilience-test.component';
import { ChartModule } from 'primeng/chart';

@NgModule({
  declarations: [
    AppComponent,
    SidenavMenuComponent,
    DashboardComponent,
    ChallengeComponent,
    FooterComponent,
    JournalComponent,
    JournalElementComponent,
    JournalEntryEditorComponent,
    ProgressComponent,
    HelpDialogComponent,
    MyOverlayComponent,
    LoginComponent,
    ResetPasswordComponent,
    ResetPasswordTokenComponent,
    RegisterComponent,
    MainComponent,
    TutorialDialog,
    LoginlayoutComponent,
    AdminComponent,
    UserComponent,
    PopupDialogComponent,
    OnboardingDirective,
    PopupWidgetComponent,
    ConfirmDialog,
    EscapeHtmlPipe,
    AnalyticsComponent,
    JourneyComponent,
    MessengerComponent,
    ResilienceTestComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    MatCardModule,
    MatGridListModule,
    MatListModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatDialogModule,
    MatCardModule,
    BrowserModule,
    FormsModule,
    MatProgressBarModule,
    NgxChartsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSliderModule,
    CdkTableModule,
    CdkTreeModule,
    HttpClientModule,
    OverlayModule,
    PortalModule,
    MatProgressSpinnerModule,
    QuillModule,
    FontAwesomeModule,
    MatStepperModule,
    ChartModule,
    //JoyrideModule.forChild(),
    JoyrideModule.forRoot(),
    JoyrideModule.forChild(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useClass: CustomTranslationLoader,
          deps: [HttpClient]
      }
  }),
    //RouterModule.forRoot([]),
    //RouterModule.forRoot([], {useHash: true}),
  ],
  entryComponents: [
    HelpDialogComponent,
    ConfirmDialog,
    PopupDialogComponent,
    PopupWidgetComponent,
    TutorialDialog
  ],
  providers: [HttpInterceptorProviders, ErrorInterceptorProviders, AuthGuard, SidenavMenuComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(faLightbulb, faWrench, faTrophy);
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
