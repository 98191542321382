import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let HTTPOptions:Object = {

  headers: new HttpHeaders({
      'Content-Type': 'application/json'
  }),
  responseType: 'text'
}

@Injectable({
    providedIn: 'root'
})

export class UserService {
    private userUrl = environment.apiUrl + 'path';
    private adminUrl = environment.apiUrl + 'test/admin';
    //httpOptions = {  headers: new HttpHeaders({ 'Content-Type': ['application/json', 'arraybuffer'] })};

    constructor(private http: HttpClient) { }

    getUserBoard(): Observable<string> {
        return this.http.get(this.userUrl, { responseType: 'text'});
    }

     getAdminBoard(): Observable<string> {
        return this.http.get(this.adminUrl, { responseType: 'text'});
    }

    setUserLanguage(language: string): Observable<string> {
      return this.http.put<string>(environment.apiUrl + 'account/language', language, HTTPOptions);
    }

    getUserLanguage(): Observable<string> {
      return this.http.get(environment.apiUrl + 'account/', { responseType: 'text'});
    }

    getUserPrivacy(): Observable<boolean> {
      return this.http.get<boolean>(environment.apiUrl + 'account/privacy');
    }
}
