import { Observable, throwError, of } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler,HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenStorageService } from "../auth/token-storage.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private tokenStorage: TokenStorageService
  ) {}

  /*intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {console.log("### catch");console.log(err);
        if (err.status == 401) {
          this.router.navigate(['/login']);
        } else {
          return throwError(err);
        }
      })
    );
  }*/
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err, caught: Observable<HttpEvent<any>>) => {
          if (request.url.indexOf('api/auth/signin') < 0 && err instanceof HttpErrorResponse && err.status == 401) {
            this.tokenStorage.signOut();
            this.tokenStorage.signSessionOut();
            this.router.navigate(['login']/*, { queryParams: { returnUrl: request.url } }*/);
            return of(err as any);
          }
          throw err;
        })
      );
  }

}
