import { MenuPoint } from "./menuPoint";
import { AppService } from "./../app.service";
import { MENUPOINTS } from "./all-menupoints";
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { JoyrideService } from "ngx-joyride";
import { Router, NavigationEnd } from "@angular/router";
import { ProgressService } from "../progress/progress.service";
import { environment } from "src/environments/environment";
import { PathService } from "../messenger/path.service";
import { AuthService } from "../auth/auth.service";
import { MatDialog, MatIconRegistry } from "@angular/material";
import {HtmlService} from '../html.service';
import {htmlDataService} from '../html.data.service';
import { DomSanitizer } from "@angular/platform-browser";
import { SecurityService } from "../auth/security-service";
import { TranslateService } from "@ngx-translate/core";
import { TokenStorageService } from "../auth/token-storage.service";

@Component({
  selector: "app-sidenav-menu",
  templateUrl: "./sidenav-menu.component.html",
  styleUrls: ["./sidenav-menu.component.scss"]
})
export class SidenavMenuComponent implements OnInit {
  ENV = environment;

  selectedMenuPoint: MenuPoint;
  menuPoints = MENUPOINTS;
  info: any;

  constructor(
    private pathservice: PathService,
    private authService: AuthService,
    private appService: AppService,
    private readonly joyrideService: JoyrideService,
    private router: Router,
    private token: TokenStorageService,
    private progressService: ProgressService,
    private htmldataservice: htmlDataService,
    private htmlservice: HtmlService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private securityService: SecurityService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {this.matIconRegistry.addSvgIcon("calendar", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/calendar.svg'))
                         .addSvgIcon("addressbook", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/addressbook.svg'))
                         .addSvgIcon("rocket", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/rocket.svg'));}

  ngOnInit() {
    this.htmldataservice.getVocabulary().subscribe(data => {
      this.htmlservice.vocabulary = data;
    });
    this.info = {
      token: this.token.getToken() || this.token.getSessionToken(),
      coachingUser: this.securityService.isCoachingUser(),
      resilienceModel: this.securityService.hasResilienceModel(),
      isUnternehmenAllowsGptUsage: this.securityService.isUnternehmenAllowsGptUsage()
    }
    
  }

  toggleAskSally(unternehmenAllowsGptUsage: boolean) {
    let askSally = document.getElementById("coaching");
    if(askSally != null) {
      if(unternehmenAllowsGptUsage) {
        askSally.style.display = "block";
      } else {
        askSally.style.display = "none";
      }
    }
  }

  onSelect(menuPoint: MenuPoint): void {
    //this.appService.setSelectedPoint(menuPoint);
  }
  ngDoCheck() {
    this.changeColor();
  }
  changeColor() {
    this.menuPoints.forEach(e => {
      if (this.appService.header != this.translate.instant('sklls.sidenav.coaching')) {
        document.getElementById("coaching").style.color = "#DC852A";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.journey')) {
        document.getElementById("journey").style.color = "#DC852A";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.journal')) {
        document.getElementById("journal").style.color = "#DC852A";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.progress')) {
        document.getElementById("progress").style.color = "#DC852A";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.resilience')) {
        document.getElementById("resilienceTest").style.color = "#DC852A";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.coaching')) {
        document.getElementById("coaching").style.backgroundColor= "#2D2D2E";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.journey')) {
        document.getElementById("journey").style.backgroundColor= "#2D2D2E";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.journal')) {
        document.getElementById("journal").style.backgroundColor= "#2D2D2E";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.progress')) {
        document.getElementById("progress").style.backgroundColor= "#2D2D2E";
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.resilience')) {
        document.getElementById("resilienceTest").style.backgroundColor= "#2D2D2E";
      }
      switch (this.appService.header) {
        case this.translate.instant('sklls.sidenav.journal'):
          document.getElementById("journal").style.backgroundColor = "#494C4D";
          document.getElementById("journal").style.color = "#FFF";
          break;
        case this.translate.instant('sklls.sidenav.journey'):
          document.getElementById("journey").style.backgroundColor = "#494C4D";
          document.getElementById("journey").style.color = "#FFF";
          break;
        case this.translate.instant('sklls.sidenav.coaching'):
          document.getElementById("coaching").style.backgroundColor = "#494C4D";
          document.getElementById("coaching").style.color = "#FFF";
          break;
        case this.translate.instant('sklls.sidenav.progress'):
          document.getElementById("progress").style.backgroundColor = "#494C4D";
          document.getElementById("progress").style.color = "#FFF";
          break;
          case this.translate.instant('sklls.sidenav.resilience'):
          document.getElementById("resilienceTest").style.backgroundColor = "#494C4D";
          document.getElementById("resilienceTest").style.color = "#FFF";
          break;
      }
    });
  }
}
