import { UserService } from "./../services/user.service";
import { Component, OnInit, DoCheck } from "@angular/core";
import { TokenStorageService } from "../auth/token-storage.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AppService } from "../app.service";
import { environment } from "src/environments/environment";
import { PathService } from "../messenger/path.service";
import { ChallengesElement } from "../challenge/challengesElement";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatDialog } from "@angular/material";
import { enableProdMode } from "@angular/core";
import { TutorialDialog } from "../tutorial/tutorial.dialog.componenten";
import { SecurityService } from "../auth/security-service";
import { TimelineJourney } from "../challenge/timelineJourney";

enableProdMode();

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit, DoCheck {
  ENV = environment;

  username = "Max Mustermann";
  info: any;
  header: String;
  hideElement: boolean = false;
  showMobilLogo: boolean = false;
  animal: string;
  challengesElement: ChallengesElement;
  isLoggedIn = false;
  name: string;
  userlanguage: string;
  requestedLanguage: string;
  errorMessage = '';
  journeyTimeline: TimelineJourney[] = [];   

  constructor(
    private tokenStorage: TokenStorageService,
    private appService: AppService,
    private router: Router,
    private pathservice: PathService,
    private activatedRoute: ActivatedRoute,
    private breaker: BreakpointObserver,
    public dialog: MatDialog,
    private userService: UserService,
    private securityService: SecurityService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      let coachingpathid = params['coachingpathid'];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (
          event.url === "/main/path" ||
          event.url === "/main/path?coachingpathid=" + coachingpathid
        ) {
          this.hideElement = true;
          this.breaker.observe(["(max-width: 667px)"]).subscribe(result => {
            if (result.matches) {
              this.showMobilLogo = true;
            } else {
              this.showMobilLogo = false;
            }
          });
        } else {
          this.hideElement = false;
          this.breaker.observe(["(max-width: 667px)"]).subscribe(result => {
            if (result.matches) {
              this.showMobilLogo = false;
            }
          });
        }
      }
    })
  });
  }

  ngDoCheck() {
    this.activatedRoute.queryParams.subscribe(params => {
      let coachingpathid = params['coachingpathid'];  
      if(this.router.url === '/main/path' || this.router.url === '/main/path?coachingpathid=' + coachingpathid) {
      this.header = this.appService.title;
      } else {
        this.header = null;
      }
    })
  }

  startPath(id: number, titel: string, type: number) {
    this.pathservice.resetStepNo();
    this.pathservice.setPathId(id);
    this.pathservice.setPathType(type);
    this.pathservice.setPathTitle(titel);
  }

  ngOnInit() {
/*    const dateFormatter = new Intl.DateTimeFormat(undefined, {
  			year: "numeric",
  			month: "numeric",
  			day: "numeric",
  	})
  	const timeFormatter = new Intl.DateTimeFormat(undefined, {
  			hour: "numeric",
  			minute: "numeric",
  	})
*/    this.info = {
      token: this.tokenStorage.getToken() || this.tokenStorage.getSessionToken(),
      fullname: this.tokenStorage.getUsername() || this.tokenStorage.getSessionUsername(),
      user: this.securityService.hasUserRole(),
      admin: this.securityService.hasAdminRole(),
      hasauditmodel: this.securityService.hasAuditModel()
/*    enddateandtime: Number(this.tokenStorage.getEndDateAndTime()) || Number(this.tokenStorage.getSessionEndDateAndTime()),
      enddateformated: dateFormatter.format(Number(this.tokenStorage.getEndDateAndTime())) || dateFormatter.format(Number(this.tokenStorage.getSessionEndDateAndTime())),
      endtimeformated: timeFormatter.format(Number(this.tokenStorage.getEndDateAndTime())) || timeFormatter.format(Number(this.tokenStorage.getSessionEndDateAndTime())),
      startdateandtime: Number(this.tokenStorage.getStartDateAndTime()) || Number(this.tokenStorage.getSessionStartDateAndTime()),
      startdateformated: dateFormatter.format(Number(this.tokenStorage.getStartDateAndTime())) || dateFormatter.format(Number(this.tokenStorage.getSessionStartDateAndTime())),
      starttimeformated: timeFormatter.format(Number(this.tokenStorage.getStartDateAndTime())) || timeFormatter.format(Number(this.tokenStorage.getSessionStartDateAndTime())),
      currentdate: Date.now()
*/    
    };
    if(this.securityService.isExpired()) {
      this.tokenStorage.signOut();
      this.tokenStorage.signSessionOut();
      window.location.reload();
    }
    this.pathservice.getInitialJourney().subscribe(
      data => {
        this.journeyTimeline = data; 
        this.activatedRoute.queryParams.subscribe(
          params => {
            let coachingpathid = params['coachingpathid'];
            let url = this.router.url;
            if(this.journeyTimeline[0] != null && this.journeyTimeline[0].finished != null && coachingpathid == null) {
              this.hideElement = false;
              this.router.navigateByUrl('/main/journey');
            } else if (this.journeyTimeline[0] != null && this.journeyTimeline[0].finished != null && coachingpathid != null && url.match(coachingpathid)) {
              return this.startPath(coachingpathid, 'element.title', this.pathservice.challengeType);
            } else {
              return this.startPath(this.journeyTimeline[0].coachingpathid, 'element.title', this.pathservice.challengeType);
            }
          }
        ),
        error => {
          this.errorMessage = error.error.message;
        }
      }
    );
    this.userService.getUserLanguage().subscribe(
      data => {
        this.userlanguage = data;
        this.activatedRoute.queryParams.subscribe(params => {
          let coachingpathid = params['coachingpathid'];
          let url = this.router.url;
          if (this.appService.selectedLanguage !== this.userlanguage && coachingpathid != null && url.match(coachingpathid)) {
            this.appService.changeLanguage(this.userlanguage);
            this.hideElement = true;
            return this.startPath(coachingpathid, 'element.title', this.pathservice.challengeType);
          } else if(coachingpathid != null && url.match(coachingpathid)) {
            this.hideElement = true;
            return this.startPath(coachingpathid, 'element.title', this.pathservice.challengeType);
          } else if(this.appService.selectedLanguage !== this.userlanguage) {
            this.appService.changeLanguage(this.userlanguage);
          }
        });
      }
    );
  }

  logout() {
    setTimeout(() => {}, 1500);
    this.tokenStorage.signOut();
    this.tokenStorage.signSessionOut();
    window.location.reload();
  }

  goMain() {
    this.router.navigateByUrl("/main/journey");
}

  goToPrivacy() {
    this.router.navigateByUrl("/login/privacy");
  }

  setDisplay() {
    const title = this.appService.getHeaderTitle();
    if (
      title === "Challenges" ||
      title === "Coaching" ||
      title === "Journal" ||
      title === "Progress"
    ) {
      this.showMobilLogo = false;
    } else {
      this.showMobilLogo = true;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(TutorialDialog, {});

    dialogRef.afterClosed().subscribe(() => {
      console.log("The dialog was closed");
    });
  }
  requestChangeUserLanguage(lang) {
    this.requestedLanguage = lang;
    if (this.router.url === '/main/path') {
      this.goMain();
    } else {
      this.changeUserLanguage();
    }
  }
  changeUserLanguage() {
    if (this.requestedLanguage !== undefined && this.appService.selectedLanguage !== this.requestedLanguage) {
      this.userService
        .setUserLanguage(this.requestedLanguage)
        .subscribe((data) => {
          this.userlanguage = data;
          this.appService.changeLanguage(data);
          this.requestedLanguage = null;
          window.location.reload();
        });
    }
  }

  goAnalytics() {
    this.router.navigateByUrl("/analytics");
  }

  goAskedQuestions() {
    window.open('https://www.sklls.de/faq', '_blank');
  }
}
