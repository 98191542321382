import { Injectable } from '@angular/core';

const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUsername';
const EMAIL_KEY = 'AuthEmail';
const ACCOUNT_UUID = 'AuthAccountExternalID';
const UNTERNEHMEN_UUID = 'AuthUnternehmenExternalID';
const STARTDATEANDTIME_KEY = 'AuthStartDateAndTime';
const ENDDATEANDTIME_KEY = 'AuthEndDateAndTime';


@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut() {
    window.localStorage.clear();
  }

  signSessionOut() {
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public saveSessionToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public getSessionToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUsername(fullname: string) {
    window.localStorage.removeItem(USERNAME_KEY);
    window.localStorage.setItem(USERNAME_KEY, fullname);
  }

  public saveSessionUsername(fullname: string) {
    window.sessionStorage.removeItem(USERNAME_KEY);
    window.sessionStorage.setItem(USERNAME_KEY, fullname);
  }

  public getUsername(): string {
    return localStorage.getItem(USERNAME_KEY);
  }

  public getSessionUsername(): string {
    return sessionStorage.getItem(USERNAME_KEY);
  }

  public saveEmail(email: string) {
    window.localStorage.removeItem(EMAIL_KEY);
    window.localStorage.setItem(EMAIL_KEY, email);
  }

  public saveSessionEmail(email: string) {
    window.sessionStorage.removeItem(EMAIL_KEY);
    window.sessionStorage.setItem(EMAIL_KEY, email);
  }
  
  public saveStartDateAndTime(startDateAndTime: string) {
	window.localStorage.removeItem(STARTDATEANDTIME_KEY);
    window.localStorage.setItem(STARTDATEANDTIME_KEY, startDateAndTime);
  }
  
   public saveSessionStartDateAndTime(startDateAndTime: string) {
	window.sessionStorage.removeItem(STARTDATEANDTIME_KEY);
    window.sessionStorage.setItem(STARTDATEANDTIME_KEY, startDateAndTime);
  }
  
  public saveEndDateAndTime(endDateAndTime: string) {
	window.localStorage.removeItem(ENDDATEANDTIME_KEY);
    window.localStorage.setItem(ENDDATEANDTIME_KEY, endDateAndTime);
  }
  
   public saveSessionEndDateAndTime(endDateAndTime: string) {
	window.sessionStorage.removeItem(ENDDATEANDTIME_KEY);
    window.sessionStorage.setItem(ENDDATEANDTIME_KEY, endDateAndTime);
  }

  public getEmail(): string {
    return localStorage.getItem(EMAIL_KEY);
  }

  public getSessionEmail(): string {
    return sessionStorage.getItem(EMAIL_KEY);
  }
  
  public getStartDateAndTime(): string {
    return localStorage.getItem(STARTDATEANDTIME_KEY);
  }
  
  public getSessionStartDateAndTime(): string {
    return sessionStorage.getItem(STARTDATEANDTIME_KEY);
  }
  
  public getEndDateAndTime(): string {
    return localStorage.getItem(ENDDATEANDTIME_KEY);
  }
  
  public getSessionEndDateAndTime(): string {
    return sessionStorage.getItem(ENDDATEANDTIME_KEY);
  }

  public getLanguange(): string {
    return window.localStorage.getItem('selectedLanguage');
  }

  public getSessionLanguange(): string {
    return window.sessionStorage.getItem('selectedLanguage');
  }

  public setLanguange(language: string) {
    window.localStorage.setItem('selectedLanguage', language);
  }

  public setSessionLanguange(language: string) {
    window.sessionStorage.setItem('selectedLanguage', language);
  }

  public getAccountUUID(): string {
    return window.localStorage.getItem(ACCOUNT_UUID);
  }

  public getSessionAccountUUID(): string {
    return window.sessionStorage.getItem(ACCOUNT_UUID);
  }

  public setAccountUUID(accountExternalId: string) {
    return window.localStorage.setItem(ACCOUNT_UUID, accountExternalId);
  }

  public setSessionAccountUUID(accountExternalId: string) {
    return window.sessionStorage.setItem(ACCOUNT_UUID, accountExternalId);
  }

  public getUnternehmenUUID(): string {
    return window.localStorage.getItem(UNTERNEHMEN_UUID);
  }

  public getSessionUnternehmenUUID(): string {
    return window.sessionStorage.getItem(UNTERNEHMEN_UUID);
  }

  public setUnternehmentUUID(unternehmenExternalId: string) {
    return window.localStorage.setItem(UNTERNEHMEN_UUID, unternehmenExternalId);
  }

  public setSessionUnternehmenUUID(unternehmenExternalId: string) {
    return window.sessionStorage.setItem(UNTERNEHMEN_UUID, unternehmenExternalId);
  }  
}
