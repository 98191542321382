import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../environments/environment';
import {Assignment} from './html-assignment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({
    providedIn: 'root'
})
// tslint:disable-next-line:class-name
export class htmlDataService {
    apiUrl = environment.apiUrl + 'vocabulary';

    constructor(private http: HttpClient) { }

    getVocabulary(): Observable<Assignment[]> {
        return this.http.get<Assignment[]>(this.apiUrl);
    }

}
