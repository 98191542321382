import { JournalEntryDetailsElement } from '../journal-element/journal-entry-details-element';

export class JournalEntry {
  title: String;
  text: String;
  createdat: Date;
  favorite: boolean;
  modifiedat: Date;
  id: number;
  coachingpathid: number;
  completed: boolean;
  tippid: number;
  detail: JournalEntryDetailsElement;
  like: Boolean;
}
