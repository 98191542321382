import { ChallengesElement } from '../challenge/challengesElement';
import { StepDto } from './stepDto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoachingPath } from './CoachingPath';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChallengesJourney } from '../challenge/challengesJourney';
import { TimelineJourney } from '../challenge/timelineJourney';

@Injectable({
  providedIn: 'root',
})
export class PathDataService {
  private apiUrlPath = environment.apiUrl;
  private apiUrlStep = environment.apiUrl + 'path/coaching-paths/step/';
  private apiUrlCoachingPath = environment.apiUrl + 'new/path/';
  private apiUrl = environment.apiUrl + 'path';
  httpClient: HttpClient;

  constructor(private http: HttpClient) {
    this.httpClient = http;
  }

  getChallenge(): Observable<ChallengesElement[]> {
    return this.http.get<ChallengesElement[]>(this.apiUrl + '/challenges/all');
  }

  getJourney(): Observable<ChallengesJourney[]> {
    return this.http.get<ChallengesJourney[]>(this.apiUrl + '/challenges/journey');
  }

  getInitialJourney(): Observable<TimelineJourney[]> {
    return this.http.get<TimelineJourney[]>(this.apiUrlPath + 'journey/user');
  }

  getChallengeFisrtStepDto(pathId: number): Observable<StepDto> {
    return this.http.get<StepDto>(this.apiUrl + '/challenges/' + pathId);
  }

  getStepDto(id: number): Observable<StepDto> {
    return this.http.get<StepDto>(this.apiUrlStep + id);
  }

  getEvaluationStepDto(
    id: number,
    journalentryid: number
  ): Observable<StepDto> {
    return this.http.get<StepDto>(this.apiUrlStep + id + '/' + journalentryid);
  }

  getNextOrOriginalStepDto(currentStepId: number, followerStepId: number): Observable<StepDto> {
    if (followerStepId == null) {
      return this.http.get<StepDto>(this.apiUrlStep + 'dtonextOrOriginal/' + currentStepId);
    } else {
      return this.http.get<StepDto>(this.apiUrlStep + 'dtonextOrOriginal/' + currentStepId + '/' + followerStepId);
    }
  }

  getNextEvaluationStepDto(
    currentStepId: number,
    journalentryid: number,
    originalStepId: number
  ): Observable<StepDto> {
    if (originalStepId == null) {
      return this.http.get<StepDto>(this.apiUrlStep + 'dtonext/' + currentStepId + '/' + journalentryid);
    } else {
      return this.http.get<StepDto>(this.apiUrlStep + 'dtonext/' + currentStepId + '/' + journalentryid + '/' + originalStepId);
    }
  }

  // Holt den ersten Step durch des PathsTypesId
  getCoachingPathsStartStepdto(typeId: number): Observable<StepDto> {
    return this.http.get<StepDto>(
      this.apiUrlPath + 'new/path/dtostartstep/' + typeId
    );
  }

  getCoachingsStartStepdto(pathId: number): Observable<StepDto> {
    return this.http.get<StepDto>(
      this.apiUrlPath + 'new/path/dtostartcoachingstep/' + pathId
    );
  }

  getEvaluationCoachingPathsStartStepdto(
    typeId: number,
    journalentryid: number
  ): Observable<StepDto> {
    return this.http.get<StepDto>(
      this.apiUrlPath + 'new/path/dtostartstep/' + typeId + '/' + journalentryid
    );
  }

  getCoachingPathsAktuellStepdto(pathId: number): Observable<StepDto> {
    return this.http.get<StepDto>(this.apiUrlPath + 'aktuellerstep/' + pathId);
  }

  getCoachingPathsLetzteStepdto(pathId: number): Observable<StepDto> {
    return this.http.get<StepDto>(this.apiUrlPath + 'letztestep/' + pathId);
  }

  getCoachingPathsLetzteStepdtoVorTipp(pathId: number): Observable<StepDto> {
    return this.http.get<StepDto>(
      this.apiUrlPath + 'letztestepvortipp/' + pathId
    );
  }

  getCoachingPathsAfterTippStepdto(pathId: number): Observable<StepDto> {
    return this.http.get<StepDto>(this.apiUrlPath + 'afterTippStep/' + pathId);
  }

  getCoachingPathsEvaluationLetzteStepdto(
    pathId: number,
    journalentyid: number
  ): Observable<StepDto> {
    return this.http.get<StepDto>(this.apiUrlPath + 'letztestep/' + pathId + '/' + journalentyid);
  }

  getCoachingPath(pathId: number): Observable<CoachingPath> {
    return this.http.get<CoachingPath>(this.apiUrlCoachingPath + pathId);
  }
}
