import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Progress} from './Progress';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { PopupDto } from '../popup-widget/popupDto';
import { ProgressDto } from './progressDto';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ProgressDataService {

  private apiUrl = environment.apiUrl + 'progress/';
  httpClient: HttpClient;

  constructor(private http: HttpClient) {
    this.httpClient = http;
   }

 /*  getProgress(): Observable<Progress> {
    return this.http.get<Progress>(this.apiUrl + 'user');
  } */

  getProgressDto(): Observable<ProgressDto> {
   return this.http.get<ProgressDto>(this.apiUrl + 'user');
  }
/*   addProgress(progressType: number): Observable<any> {
    return this.http.put<number>(this.apiUrl + 'add/progressType/' + progressType, httpOptions );
  } */

  addProgress(progressType: number): Observable<PopupDto> {
    return this.http.put<PopupDto>(this.apiUrl + 'add/progressType/' + progressType, httpOptions );
  }

  getProgressTutorial(): Observable<Progress>{
    return  this.http.get<Progress>(this.apiUrl + 'progress/tutorial/');
  }

}
