// import { Component, OnInit, AfterViewInit} from '@angular/core';
import { TokenStorageService } from "./auth/token-storage.service";
import { MENUPOINTS } from "./sidenav-menu/all-menupoints";
import { AppService } from "./app.service";
import { Component, OnInit, DoCheck } from "@angular/core";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, DoCheck {
  title = "sklls starter";
  faLightbulb = faLightbulb;
  faWrench = faWrench;
  faTrophy = faTrophy;
  private roles: string[];
  private authority: string;
  private c: string;
  public selectedLanguage: string;

  menuPoints = MENUPOINTS;

  constructor(
    public appService: AppService,
    public router: Router
  ) {}
  ngDoCheck() {
    this.appService.getHeaderTitle();
  }

  ngOnInit() {
    this.appService.getLanguage();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
  .subscribe(() => {
      const contentContainer = document.querySelector('.mat-sidenav-content') || window;
      contentContainer.scrollTo(0, 0);
});
  }

  ngAfterViewInit(){
  }

}
