export class StepDto {
  id: number;
  steptypeid: number;
  coachtext: String;
  helptext: string;
  answervalues: string[];
  answerentries: string[];
  answermin: number;
  answermax: number;
  internalid: number;
  pathId: number;
  skippto: number;
  pathTitel: string;
  originalStepId: number;
  jumpToPathid: number;
  jumpOut: boolean;
}
