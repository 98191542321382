import { StepDto } from '../messenger/stepDto';

export class UserInput {
  id: number;
  stepid: StepDto;
  startTime: Date;
  endTime: Date;
  inputcontent: string;
  accountid: object;
  predecessorstepid: StepDto;
}
