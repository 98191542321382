import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JournalEntry } from './journal-entry';
import { JournalEntryDetailsElement } from '../journal-element/journal-entry-details-element';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { JournalEntryDto } from './journalEntryDto';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({
    providedIn: 'root',

})
export class JournalDataService {
  apiUrl = environment.apiUrl + 'journal';
  httpOptions = {  headers: new HttpHeaders({ 'Content-Type': ['application/json', 'arraybuffer'] })};

  constructor(private http: HttpClient) { }

  getJournals(): Observable<JournalEntryDto[]> {
    return this.http.get<JournalEntryDto[]>(this.apiUrl + '/user');
}

  updateJournal(journalEntry: JournalEntry): Observable<JournalEntry> {
      return this.http.put<JournalEntry>(this.apiUrl, journalEntry, httpOptions);
  }

  /*addJournal(journalEntry: JournalEntry): Observable<JournalEntry>{
      return this.http.post<JournalEntry>(this.apiUrl, journalEntry, httpOptions);
  }*/

  saveJournal(journalEntry: JournalEntry, details: JournalEntryDetailsElement[]): Observable<JournalEntry> {
      return this.http.post<JournalEntry>(this.apiUrl + '/v2', {journalEntry, details}, httpOptions);
  }

  saveNewJournal(journalEntry: JournalEntry, details: JournalEntryDetailsElement[], endStepid: number): Observable<JournalEntry> {
    return this.http.post<JournalEntry>(this.apiUrl + '/new/', {journalEntry, details, endStepid}, httpOptions);
  }

}
