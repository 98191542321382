import { Injectable } from "@angular/core";
import { Assignment } from "./html-assignment";

@Injectable({
  providedIn: "root"
})
export class HtmlService {
  vocabulary = new Array<Assignment>();
  constructor() {}
  convertArraytoHtml(textarray: string[]) {
    const htmltextarray = new Array<string>();
    textarray.forEach(element => {
      element = this.removeQuoationMark(element);
      htmltextarray.push(this.convertToHtml(element));
    });
    return htmltextarray;
  }

  removeQuoationMark(element: string) {
    if (element.includes('Immer wenn "')) {
      element = element.replace('Immer wenn "', "Immer wenn ");
      console.log(element);
    }

    if (element.includes('If "')) {
      element = element.replace('If "', "If ");
      console.log(element);
    }

    if (element.includes('" !')) {
      element = element.replace('" !', "!");
      console.log(element);
    }

    return element;
  }

  convertToHtml(text: string) {
    this.vocabulary.forEach(element => {
      if (!element.closable) {
        text = this.replaceSensitively(text, element);
      } else {
        text = this.replaceAlternating(text, element);
      }
    });
    return text;
  }

  replaceSensitively(text: string, assignment: Assignment) {
    text = this.replaceAll(text, "'" + assignment.dbtag, "Raketendachs");
    text = this.replaceAll(text, assignment.dbtag, assignment.htmltag);
    text = this.replaceAll(text, "Raketendachs", assignment.dbtag);
    return text;
  }

  replaceAll(text: string, term: string, replacement: string) {
    if (text !== null && text !== undefined) {
      const textsplit = text.split(term);
      text = "";
      for (let i = 0; i < textsplit.length - 1; i++) {
        text = text.concat(textsplit[i].concat(replacement));
      }
      text = text.concat(textsplit[textsplit.length - 1]);
    } else {
      console.log("WARN: text null/undefined (html.service.ts:60)");
    }
    return text;
  }

  replaceAlternating(text: string, assignment: Assignment) {
    text = this.replaceAll(text, "'" + assignment.dbtag, "Raketendachs");
    if (text !== null && text !== undefined) {
      const textsplit = text.split(assignment.dbtag);
      text = "";
      let opening = true;
      for (let i = 0; i < textsplit.length - 1; i++) {
        if (opening) {
          textsplit[i] = textsplit[i].concat(assignment.htmltag);
        } else {
          textsplit[i] = textsplit[i].concat(
            assignment.htmltag.slice(0, 1) + "/" + assignment.htmltag.slice(1)
          );
        }
        text = text.concat(textsplit[i]);
        opening = !opening;
      }
      text = text.concat(textsplit[textsplit.length - 1]);
      text = this.replaceAll(text, "Raketendachs", assignment.dbtag);
    }
    return text;
  }
}
