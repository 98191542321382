import { StepDto } from './stepDto';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserInput } from '../user/userInput';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StepDataService {

  private apiUrl = environment.apiUrl + 'path/step/';
  httpClient: HttpClient;

  constructor(private http: HttpClient) {
    this.httpClient = http;
  }

  addUserInput(userInput: UserInput): Observable<any> {
    return this.http.put<UserInput>(this.apiUrl, userInput, httpOptions);
  }

  getUserInputDB(stepid: number): Observable<any> {
    return this.http.get<StepDto>(this.apiUrl + stepid);
  }

  getUserInputById(id: number): Observable<any> {
    return this.http.get<UserInput>(environment.apiUrl + 'path/userinput/' + id)
  }

   saveUserInput(userInput: UserInput): Observable<UserInput> {
    return this.http.put<UserInput>(environment.apiUrl + 'path/saveuserinput/', userInput, httpOptions);
  }

  getLastUserInput(stepid: number): Observable<any> {
    return this.http.get<UserInput>(environment.apiUrl + 'path/lastuserinput/' + stepid);
  }

  deleteUserInputsByStep(stepId: number): Observable<any> {
    return this.http.delete<UserInput>(this.apiUrl + 'deletedatabystep/' + stepId);
  }

  deleteUserInputs(latestStepId: number): Observable<any> {
    return this.http.delete<UserInput>(this.apiUrl + 'deletedata/' + latestStepId);
  }

  deleteAllOpenUserInputs(): Observable<any> {
    return this.http.delete<UserInput>(this.apiUrl + 'deleteAll');
  }

  completeUserInputs(latestStepId: number): Observable<any> {
    return this.http.delete<UserInput>(this.apiUrl + 'completedata/' + latestStepId);
  }
}
