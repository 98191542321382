import { Injectable } from '@angular/core';
import { MyOverlayComponent } from './my-overlay/my-overlay.components';

@Injectable({providedIn: 'root'})
export class OverlayServiceService {

  constructor() { }

  private overlays: Map<number, MyOverlayComponent> = new Map();
  private latestShownOverlayId = -1;

  public showOverlay(id: number) {
    const overlay = this.overlays.get(id);
    if (overlay) {
      overlay.showOverlay();
    }
  }

  public registerOverlay(overlay: MyOverlayComponent) {
    this.overlays.set(overlay.id, overlay);
  }

  public destroyOverlay(overlay: MyOverlayComponent) {
    this.overlays.delete(overlay.id);
  }

  public wasClosed(overlayId: number) {
    this.latestShownOverlayId = overlayId;
    const overlay = this.overlays.get(this.latestShownOverlayId + 1);
    if (overlay) {
      setTimeout(() => overlay.showOverlay());
    }
  }

}
