import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from '../auth/security-service';
import { TokenStorageService } from '../auth/token-storage.service';
import { ChallengesElement } from '../challenge/challengesElement';
import { ChallengesJourney } from '../challenge/challengesJourney';
import { JournalEntry } from '../journal/journal-entry';
import { JournalService } from '../journal/journal-service.service';
import { ProgressService } from '../progress/progress.service';
import { PathService } from '../messenger/path.service';
import { FixedPositions } from './fixedpositions';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { TimelineJourney } from '../challenge/timelineJourney';
import { AppService } from '../app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.scss']
})
export class JourneyComponent implements OnInit {
  ENV = environment;
  positions: FixedPositions[] = [];
  errorMessage: '';
  info: any;
  challengesElement: ChallengesElement;
  userJourney: ChallengesJourney[] = [];
  journeyTimeline: TimelineJourney[] = [];
  currenttime: Number;
  startdateandtime: Number;
  auditStarted: boolean;

  constructor(private tokenStorage: TokenStorageService,
              private securityService: SecurityService,
              private router: Router,
              private pathservice: PathService,
              private appService: AppService,
              private journalService: JournalService,
              private progressService: ProgressService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer
            ) {this.matIconRegistry.addSvgIcon("search", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/search.svg'))
                                    .addSvgIcon("watch", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/watch.svg')); }

  ngOnInit() {
    this.info = {
      token: this.tokenStorage.getToken() || this.tokenStorage.getSessionToken(),
      fullname: this.tokenStorage.getUsername() || this.tokenStorage.getSessionUsername(),
      user: this.securityService.hasUserRole(),
      admin: this.securityService.hasAdminRole(),
      auditModel: this.securityService.hasAuditModel()
    };
    
    this.currenttime =  Number(Date.now())
	this.startdateandtime = Number(this.tokenStorage.getStartDateAndTime()) || Number(this.tokenStorage.getSessionStartDateAndTime())
    
    if (this.securityService.hasAuditModel() == false) {
		this.auditStarted = true;
	}
    if (this.securityService.hasAuditModel() && this.currenttime < this.startdateandtime) {
    	this.auditStarted = false;
    	let intervalTime = Number(this.startdateandtime) - Number(this.currenttime);
    	window.setTimeout(this.showFirstAuditChallenge, intervalTime);
    } 
    if (this.securityService.hasAuditModel() && this.currenttime >= this.startdateandtime) {
		this.auditStarted = true;
	}
    
    this.pathservice.getInitialJourney().subscribe(
      data => {
        this.journeyTimeline = data;
        if(this.journeyTimeline[0] != null && this.journeyTimeline[0].finished != null) {
          this.router.navigateByUrl('/main/journey');
        } else {
          this.router.navigateByUrl('/main/path');
        }
      }   
    );
   
  }

  startPath(id: number, titel: string, type: number) {
    this.pathservice.resetStepNo();
    this.pathservice.setPathId(id);
    this.pathservice.setPathType(type);
    this.pathservice.setPathTitle(titel);
  }

  startCoaching(typeId: number, titel: string) {
    this.pathservice.resetStepNo();
    if (typeId === this.pathservice.challengeType) {
      this.pathservice.setPathId(this.challengesElement.coachingpathid.id);
    }
    this.pathservice.setPathType(typeId);
    this.pathservice.setPathTitle(titel);
  }

  setCompleted(id: number) {
    var i =0;
    let journalEntry = new JournalEntry;
    for(i=0;i< this.journalService.journalentriesDto.length; i++){
      if(id == this.journalService.journalentriesDto[i].journalEntry.coachingpathid){
        journalEntry= this.journalService.journalentriesDto[i].journalEntry;
      }
    }
    journalEntry.completed = true;
      this.journalService.updateJournalEntries(journalEntry);
      this.progressService.addPassedChallengesToProgress();
  
  }
  
  showFirstAuditChallenge() {
	  this.auditStarted = true;
	  document.getElementsByClassName('locked-background')[0].classList.remove('locked-background');
	  document.getElementsByClassName('locked mat-card-div')[0].classList.add('not-started', 'unlocked');
	  document.getElementsByClassName('locked mat-card-div')[0].classList.remove('locked', 'mat-card-div');	
	  document.getElementsByClassName('first-session-locked')[0].removeAttribute("disabled");	 
  }
  
}