import { UserInput } from "../user/userInput";
import { PathDataService } from "./path.data.service";
import { Injectable } from "@angular/core";
import { UserPath } from "../user/user-path";
import { JournalService } from "../journal/journal-service.service";
import { JournalEntryDetailsElement } from "../journal-element/journal-entry-details-element";
import { PathIdStack } from "./path-id-stack";
import { Answer, DoppelAnswer } from "./answer-entry";
import { Router } from "@angular/router";
import { AppService } from "../app.service";
import { MatDialog } from "@angular/material";
import { ProgressService } from "../progress/progress.service";
import { CoachingPath } from "./CoachingPath";
import { StepDataService } from "./step.data.service";
import { StepDto } from "./stepDto";
import { JournalEntry } from "../journal/journal-entry";
import { ChallengesElement } from "../challenge/challengesElement";
import { Observable } from "rxjs";
import { JournalDetailsDataService } from "../journal-element/Journal.Details.data.service";
import { JournalDataService } from "../journal/journal.data.service";
import { Tipp, Tippinputlog } from "./tipp";
import { ChallengesJourney } from "../challenge/challengesJourney";
import { TimelineJourney } from "../challenge/timelineJourney";
import { SecurityService } from "../auth/security-service";

@Injectable({
  providedIn: "root"
})
export class PathService {
  vorherigerStepId: number;
  loading: boolean;
  loadSpinner: boolean;
  testpath: ChallengesElement[];
  propagate = false;
  progressbarMax: number;
  progressbarNow: number;
  path = new Array<StepDto>();
  pathId: number;
  pathType: number;
  pathtitle: string;
  tippDisplayed = false;
  stepdto: StepDto;
  pathStepIndex: number;
  stepNo = -1;
  userData = new Array<UserPath>();
  userinputdb: UserInput;
  lastUserInput: UserInput;
  lastUserInputId: number;
  pathIdStack = new Array<PathIdStack>();
  choiceArray: Array<Answer>;
  journalEntry = new JournalEntry();
  journalentrydetailselement: JournalEntryDetailsElement[] = new Array<
    JournalEntryDetailsElement
  >();
  additionalTippText: String;
  coachingPath: CoachingPath;
  tipp: Tipp;
  tippStepNo = -1;
  tipparray: Tipp[];
  textalg: String;
  evaluatePos = 9;
  evaluateNeg = 10;
  celebrateStart = 1;
  reviewStart = 3;
  prepareStart = 6;
  challengeType = 11;

  constructor(
    private progressService: ProgressService,
    private journalservice: JournalService,
    private journaldataservice: JournalDataService,
    public appservice: AppService,
    public router: Router,
    public dialog: MatDialog,
    private pathdataService: PathDataService,
    private stepDataService: StepDataService,
    private journaldetailsdataservice: JournalDetailsDataService,
    private securityService: SecurityService
  ) {}

  getChallenge(): Observable<ChallengesElement[]> {
    return this.pathdataService.getChallenge();
  }

  getJourney(): Observable<ChallengesJourney[]> {
    return this.pathdataService.getJourney();
  }

  getInitialJourney(): Observable<TimelineJourney[]> {
    return this.pathdataService.getInitialJourney();
  }

  getCoachingPath(pathid: number): Observable<CoachingPath> {
    return this.pathdataService.getCoachingPath(pathid);
  }

  setPathTypeEvaluatePos() {
    this.setPathType(this.evaluatePos);
  }

  setPathTypeEvaluateNeg() {
    this.setPathType(this.evaluateNeg);
  }

  setPathTypeCelebrateStart() {
    this.setPathType(this.celebrateStart);
  }

  setPathTypeReviewStart() {
    this.setPathType(this.reviewStart);
  }

  setPathTypePrepareStart() {
    this.setPathType(this.prepareStart);
  }

  setPathType(typeId: number) {
    this.pathType = typeId;
  }

  setTipp(tipp: Tipp) {
    this.tipp = tipp;
  }

  getTipp() {
    return this.tipp;
  }

  setTippArray(tipp: Tipp[]) {
    this.tipparray = tipp;
  }

  getTippArray() {
    return this.tipparray;
  }

  setTippDisplayed(displayed: boolean) {
    this.tippDisplayed = displayed;
  }

  getTippDisplayed() {
    return this.tippDisplayed;
  }

  getTippId() {
    if (this.tippDisplayed) {
      return this.tipp.id;
    } else {
      return null;
    }
  }

  setadditionalTippText(text: String) {
    this.additionalTippText = text;
  }

  getFirstStepDTO(): any {
    if (
      this.pathType === this.evaluatePos ||
      this.pathType === this.evaluateNeg
    ) {
      return this.pathdataService.getEvaluationCoachingPathsStartStepdto(
        this.pathType,
        this.getJournalEntry().id
      );
    } else if (this.pathType === this.challengeType) {
      return this.pathdataService.getChallengeFisrtStepDto(this.pathId);
    }
     else if(this.pathType === this.prepareStart && this.pathId > 0) {
      return this.pathdataService.getCoachingsStartStepdto(this.pathId);
    } else if(this.pathType === this.reviewStart && this.pathId > 0) {
      return this.pathdataService.getCoachingsStartStepdto(this.pathId);
    }
    else if(this.pathType === this.celebrateStart && this.pathId > 0) {
      return this.pathdataService.getCoachingsStartStepdto(this.pathId);
    } 
    else {
      return this.pathdataService.getCoachingPathsStartStepdto(this.pathType);
    }
  }

  getAkteullStepDTO(): any {
    let aktuellStepDto = new StepDto();
    this.pathdataService
      .getCoachingPathsAktuellStepdto(this.pathId)
      .subscribe(data => {
        aktuellStepDto = data;
      });
    return aktuellStepDto;
  }

  getLetzteStepDTO(afterTipp: boolean): any {
    if (
      this.pathType === this.evaluatePos ||
      this.pathType === this.evaluateNeg
    ) {
      // FIXME: check if afterTippStep needed here?
      return this.pathdataService.getCoachingPathsEvaluationLetzteStepdto(
        this.pathId,
        this.getJournalEntry().id
      );
    } else {
      if (afterTipp) {
        return this.pathdataService.getCoachingPathsAfterTippStepdto(
          this.pathId
        );
      } else {
        return this.pathdataService.getCoachingPathsLetzteStepdto(this.pathId);
      }
    }
  }

  getLetzterStepVorTipp(): any {
    return this.pathdataService.getCoachingPathsLetzteStepdtoVorTipp(
      this.pathId
    );
  }

  setStepDto(data: StepDto) {
    this.stepdto = data;
    this.appservice.setHeaderTitle(this.pathtitle);
    this.stepNo++;
    this.pathStepIndex = 0;
  }

  setPathIdStack() {
    this.pathIdStack.push({
      pathId: this.pathId,
      resumable: false,
      pathType: this.getPathType()
    });
  }

  removePathIdFromStack() {
    this.pathIdStack.pop();
  }

  getNextOrOriginalStepDto(currentStepId: number, followerStepId: number): any {
    this.stepNo++;
    if (
      this.pathType === this.evaluatePos ||
      this.pathType === this.evaluateNeg
    ) {
      return this.pathdataService.getNextEvaluationStepDto(
        currentStepId,
        this.getJournalEntry().id,
        followerStepId
      );
    } else {
      return this.pathdataService.getNextOrOriginalStepDto(currentStepId, followerStepId);
    }
  }

  getStepDto(stepId: number): any {
    this.stepNo++;
    if (
      this.pathType === this.evaluatePos ||
      this.pathType === this.evaluateNeg
    ) {
      return this.pathdataService.getEvaluationStepDto(
        stepId,
        this.getJournalEntry().id
      );
    } else {
      return this.pathdataService.getStepDto(stepId);
    }
  }

  // wird getNextStep() NULL übergeben, speichert die Methode stattdessen und dirigiert ins Journal.
  pathEnde(endStep: number): void {
    // ERWEITERN FÜR ZURÜCK!!!
    if (
      this.getPathType() !== this.evaluatePos &&
      this.getPathType() !== this.evaluateNeg
    ) {
      this.savetoJournal(endStep);
      if (this.getPathType() === this.challengeType) {
        // Laut spezifikation in Challenge: erfahrung erst setzen, wenn der User die Challenge als erledigt markiert.
        //this.progressService.addPassedChallengesToProgress();
      } else if(this.securityService.hasResilienceModel() === false) {
        this.progressService.addCoachedTasksToProgress();
      }
    }
    if (
      this.getPathType() === this.evaluatePos ||
      this.getPathType() === this.evaluateNeg
    ) {
      this.saveEvaluationInJournalEntry(endStep);
      this.progressService.addEvaluatedSolutionsToProgress();
    }
    this.pathIdStack.forEach(element => {
      if (element.pathId === this.pathId) {
        element.resumable = true;
      }
    });
    this.userData.length = 0;
    // Workaround: kleine Wartezeit einbauen, sodass alle Daten gespeichert werden können
    this.loading = true;
    setTimeout(() => {
      if(endStep == 1016 || endStep == 1053 || endStep == 1199 || endStep == 1326) {
        this.router.navigateByUrl('/main/journey');
      } else if(endStep == 1111) {
        this.router.navigateByUrl('/main/resilience-radar')
      } else {
      this.router.navigateByUrl("main/journal");
      // TODO: die 2 Sekunden Timeout sollten die Buttons nicht klickbar sein
      // Sonst könnte es sein, dass nochmal der Button geklickt wird.
      // am besten alles ausblenden und ladescreen z.B.:
      // this.loading = true; (nicht vergessen bei onInit wieder auf false zu setzen)
      // und im template ngIf=loading anderes div mit ladescreen anzeigen
      }
    }, 2000);
  }

  deleteUserInputsByStep(stepId: number) {
    this.stepDataService.deleteUserInputsByStep(stepId).subscribe();
  }

  deleteUserInputs(latestStepId: number) {
    this.stepDataService.deleteUserInputs(latestStepId).subscribe();
  }

  deleteAllUserInputs() {
    this.stepDataService.deleteAllOpenUserInputs().subscribe();
  }

  completeUserInputs(latestStepId: number) {
    this.stepDataService.completeUserInputs(latestStepId).subscribe();
  }

  // Speichert Nutzereingaben an Stepende
  saveUserInput(userInput: Array<string>) {
    this.userData.push({
      pathType: this.pathType,
      pathId: this.pathId,
      stepNo: this.stepNo,
      stepId: this.stepdto.id,
      userInput: this.deepcopyStringArray(userInput)
    });
  }

  // packt gewrappte Strings aus
  getStringArrayFromAnswer(answer: Answer[]) {
    const convertedAnswer = new Array<string>();
    answer.forEach(element => {
      convertedAnswer.push(element.answer);
    });
    return convertedAnswer;
  }

  // wrappt Stringarray zu Answerarray
  wrapStringArraytoAnswer(array: string[]) {
    const convertedString = new Array<Answer>();
    array.forEach(element => {
      convertedString.push({ answer: element });
    });
    return convertedString;
  }

  getAnswerFromDoppelAnswer(answerentriesMulti: DoppelAnswer[]) {
    const convertedDoppelAnswer = new Array<Answer>();
    answerentriesMulti.forEach(element => {
      if (element.answerWenn !== undefined && element.answerWenn !== "") {
        convertedDoppelAnswer.push({ answer: element.answerWenn });
      }
      if (element.answerDann !== undefined && element.answerDann !== "") {
        convertedDoppelAnswer.push({ answer: element.answerDann });
      }
    });
    return convertedDoppelAnswer;
  }

  wrapAnswerArraytoDoppelAnswer(answer: Answer[]) {
    const convertAnswer: DoppelAnswer[] = [new DoppelAnswer()];
    let i = 0,
      j = 0;
    while (i < answer.length) {
      convertAnswer[j] = new DoppelAnswer();
      convertAnswer[j].answerWenn = answer[i++].answer;

      if (answer[i] != undefined && answer[i].answer != undefined)
        convertAnswer[j].answerDann = answer[i].answer;
      j++;
      i++;
    }
    return convertAnswer;
  }

  // kontrolliert ob es einen Path mit der aktuelle Type offen gibt
  newPathType(): boolean {
    let newpath = true;
    this.pathIdStack.forEach(element => {
      if (this.pathType === element.pathType) {
        if (!element.resumable) {
          this.setPathId(element.pathId);
          newpath = false;
        }
      }
    });
    return newpath;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  // erstellt aus den Daten des Pfaddurchganges einen Journaleintrag
  savetoJournal(latestStepId: number) {
    this.journaldetailsdataservice
      .transformToDetails(latestStepId)
      .subscribe(data => {
        this.delay(3000);
        this.journalentrydetailselement[0] = data;
        this.journaldataservice
          .saveNewJournal(
            {
              title: this.pathtitle,
              text: this.additionalTippText,
              id: this.journalservice.getId(),
              createdat: new Date(Date.now()),
              favorite: false,
              modifiedat: new Date(Date.now()),
              coachingpathid: this.pathId,
              completed: false,
              tippid: this.getTippId(),
              detail: null,
              like: null
            },
            this.journalentrydetailselement,
            latestStepId
          )
          .subscribe(() => {
            this.completeUserInputs(latestStepId);
            this.tippDisplayed = false;
          });
      });
  }

  // Speichert das EvaluationCoaching als JournalDetail im Journal
  saveEvaluationInJournalEntry(latestStepId: number) {
    const evaluationDetail = new JournalEntryDetailsElement();
    evaluationDetail.journalEntryId = new JournalEntry();
    evaluationDetail.journalEntryId.id = this.getJournalEntry().id;
    evaluationDetail.tippid = this.getTippId();
    evaluationDetail.pathTypeId.id = this.getPathType();
    console.log(
      "Tippid ",
      this.getTippId(),
      "evaluationTippid ",
      evaluationDetail.journalEntryId.tippid
    );
    this.journaldetailsdataservice
      .addEvaluationDetails(evaluationDetail, latestStepId)
      .subscribe(data => this.completeUserInputs(latestStepId));
    this.tippDisplayed = false;
  }

  setJournalEntry(journalentry: JournalEntry) {
    this.journalEntry = journalentry;
  }

  getJournalEntry() {
    return this.journalEntry;
  }

  // Kopiert die Werte eines Stringarrays in ein neues Stringarray, um Call by Reference zu umgehen
  deepcopyStringArray(original: Array<string>) {
    let copy = new Array<string>();
    for (let i = 0; i < original.length; i++) {
      //
      if (original[i] != "") copy.push(original[i]);
    }
    return copy;
  }

  //Kopiert die Werte eines Answerarrays in ein neues Answerarray, um Call by Reference zu umgehen
  deepcopyAnswerArray(original: Array<Answer>) {
    let copy = new Array<Answer>();
    this.stepDataService.getUserInputDB(this.stepdto.id).subscribe(data => {
      this.userinputdb = data;
    });

    // if(this.userinputdb !== undefined && this.userinputdb !== null){
    //   this.lastUserInputId = this.userinputdb.id - 1;
    //   this.stepDataService.getUserInputById(this.lastUserInputId).subscribe(data => {
    //     this.lastUserInput = data;
    //   });

    //   if( this.lastUserInput !== null && this.lastUserInput !== undefined){
    //     if(this.lastUserInput.inputcontent.split(',').length < original.length){
    //     for (let i = 0; i < this.lastUserInput.inputcontent.split(',').length; i++) {
    //       copy.push(new Answer());
    //       copy[i].answer = original[i].answer;
    //     }
    //     return copy;
    //   }
    //   if(this.lastUserInput.inputcontent.split(',').length > original.length)
    //   {
    //     for (let i = 0; i < original.length; i++) {
    //       copy.push(new Answer());
    //       copy[i].answer = this.lastUserInput.inputcontent.split(',').values[i].answer;
    //     }
    //     return copy;
    //   }
    //   }
    // }

    for (let i = 0; i < original.length; i++) {
      copy.push(new Answer());
      copy[i].answer = original[i].answer;
    }
    return copy;
  }

  // Setzt Id des vom Service zu betrachtenden Pfades
  setPathId(pathId: number) {
    this.pathId = pathId;
  }
  // erzählt die aktuelle PathId jedem, ders wissen will
  getPathId() {
    return this.pathId;
  }

  // erzählt den aktuellen PathType jedem, ders wissen will
  getPathType() {
    return this.pathType;
  }

  // Setzt den serviceinternen Schrittzähler auf Anfang zurück. (Für, wenn Pfad beendet) :: ÜBERHAUPT NOCH NOTWENDIG?!
  resetStepNo() {
    this.stepNo = -1;
  }

  resetPathId() {
    this.pathId = -1;
  }

  // Holt die UserInputs eines Steps aus der DB
  getUserInputDB(stepId: number): any {
    return this.stepDataService.getUserInputDB(stepId);
  }

  // setzt den Titel des Pfades fest.
  setPathTitle(title: string) {
    this.pathtitle = title;
  }

  setTextalg(textalg: string) {
    this.textalg = textalg;
  }

  getTextalg() {
    return this.textalg;
    // return "Hier würde dann die Anfrage, die an den 'Tipp-Algorithmus' geschickt wurde stehen."
  }

  // entfern Escape Character: \ von \" wenn es gibt
  removeEscapeCharacter(text: string) {
    while (text.indexOf('\\"') !== -1) {
      text = text.replace('\\"', '"');
    }
    return text;
  }
}
