export class Answer {
  answer: string;
}

export class Slider {
  min: number;
  max: number;
  step: number;
  value: number;
}

export class DoppelAnswer {
  answerWenn: string;
  answerDann: string;
}
