import {ResetPasswordTokenInfo} from './resetPasswordToken-info';
import {Component, OnInit} from '@angular/core';
import {ResetPasswordTokenService} from './resetPasswordToken-service';
import {TokenStorageService} from '../auth/token-storage.service';
import {environment} from 'src/environments/environment';
import {CustomTranslationLoader} from '../translate/translate.service';
import {AppService} from '../app.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: "app-login",
  templateUrl: "./resetPasswordToken.component.html",
  styleUrls: ["./resetPasswordToken.component.scss"],
})
export class ResetPasswordTokenComponent implements OnInit {
  ENV = environment;
  form: any = {};
  hasError = false;
  isSuccessful = false;
  errorMessage = "";
  private resetPasswordTokenInfo: ResetPasswordTokenInfo;

  constructor(
    private resetPasswordTokenService: ResetPasswordTokenService,
    private tokenStorage: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public customTranslationLoader: CustomTranslationLoader,
    private appService: AppService,
  ) {}

  ngOnInit() {
  }

  onSubmit() {
    console.log(this.form);
    this.resetPasswordTokenInfo = new ResetPasswordTokenInfo(this.form.email);
    this.resetPasswordTokenService.resetPasswordToken(this.resetPasswordTokenInfo).subscribe(
      data => {
        this.isSuccessful = true;
      },
      error => {
        console.log(error);
        this.errorMessage = error.error.message;
        this.hasError = true;
      }
    );
  }

  onChangeLanguage(lang) {
    this.tokenStorage.setLanguange(lang);
    this.tokenStorage.setSessionLanguange(lang);
    this.appService.changeLanguage(lang);
  }
}
