import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CustomTranslationLoader implements TranslateLoader {
    loading = false;

    httpClient: HttpClient;

    constructor(private http: HttpClient,
      // private translate: TranslateService
      ) {
      this.httpClient = http;
    }

    public getTranslation(lang: string): Observable<any> {
        let link: string = environment.apiUrl + 'languages/{lang}';
        // Zum Testen:
        // lang = 'en';
        if (link === null) {
            link = environment.apiUrl + 'languages/{lang}';
        }
        return new Observable<any>((observer: any) => {const obs: any = this.http.get(link.replace('{lang}', lang));

            obs.subscribe(
                (text: any) => {
                    observer.next(text);
                    observer.complete();
                },
                (err: any) => {
                    // alert("Failed to load language texts!");
                    observer.complete();
                    console.log('Failed to load language resources!',
                        err
                    );
                }
            );
        });
    }

    public getAcceptLanguage() {
      return this.http.get<string>(environment.apiUrl + 'languages/');
    }
}
