import { ChallengeComponent } from './challenge/challenge.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JournalComponent } from './journal/journal.component';
import { JournalEntryEditorComponent } from './journal-entry-editor/journal-entry-editor.component';
import { MessengerComponent } from './messenger/messenger.component';
import { MainComponent } from './main/main.component';
import { ProgressComponent } from './progress/progress.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginlayoutComponent } from './loginlayout/loginlayout.component';
import { AuthGuard } from './auth/auth.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { AnalyticsComponent } from './analytics/analytics.component';
import { JourneyComponent } from './journey/journey.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { ResetPasswordTokenComponent } from './resetPasswordToken/resetPasswordToken.component';
import { ResilienceTestComponent } from './resilience-test/resilience-test.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', data: { title: 'First Component' }, pathMatch: 'full' },
  {
    path: 'login', component: LoginlayoutComponent, data: {title: 'First Component'},
    children: [
      {path: '', component: LoginComponent},
      {path: 'register', component: RegisterComponent}
    ]
  },
  { path: 'resetPassword', component: ResetPasswordComponent },
  { path: 'resetPasswordToken', component: ResetPasswordTokenComponent },
  { path: 'analytics' , component: AnalyticsComponent, canActivate: [AuthGuard], data: {role: 'ROLE_ADMIN'}},
  { 
    path: 'main', component: MainComponent, canActivate: [AuthGuard], data: {role: 'ROLE_USER'},
  children: [
  { path: '', component: DashboardComponent, canActivateChild: [AuthGuard]},
  { path: 'journey', component: JourneyComponent, canActivateChild: [AuthGuard]},
  { path: 'journal', component: JournalComponent, canActivateChild: [AuthGuard]},
  { path: 'editor', component: JournalEntryEditorComponent, canActivateChild: [AuthGuard]},
  // tslint:disable-next-line:max-line-length
  { path: 'path', component: MessengerComponent, canActivateChild: [AuthGuard], runGuardsAndResolvers: 'always', canDeactivate:[CanDeactivateGuard]},
  { path: 'fortschritt', component: ProgressComponent, canActivateChild: [AuthGuard]},
  { path: 'resilience-radar', component: ResilienceTestComponent, canActivateChild: [AuthGuard]},
  { path: 'challenges' , component: ChallengeComponent, canActivateChild: [AuthGuard]}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
