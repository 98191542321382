export class SignUpInfo {
    /*     name: string; */
        fullname: string;
        email: string;
        password: string;
        privacyaggrement: boolean;
        userlanguage: string;
    
        constructor(/* name: string, */ fullname: string, email: string, password: string, agreement: boolean, userlangauge: string) {
    /*         this.name = name; */
            this.fullname = fullname;
            this.email = email;
            this.password = password;
            this.privacyaggrement = agreement;
            this.userlanguage = userlangauge;
        }
    }
    