import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tipp, Kompetenz, Tippinputlog, TippLang } from './tipp';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class HintDataService {
  apiUrl = environment.apiUrl + 'hint';

  constructor(private http: HttpClient) { }

  getTipps(text: String): Observable<Tipp[]> {
    return this.http.get<Tipp[]>(this.apiUrl + '/' + text);
  }

  getAllTipps(): Observable<TippLang[]> {
    return this.http.get<TippLang[]>(this.apiUrl + '/');
  }

  filterTippsOne(category: String, verbandnoun: String[]): Observable<Tipp[]> {
    return this.http.post<Tipp[]>(this.apiUrl + '/filter1/' + category, verbandnoun, httpOptions);
  }

  filterTippsTwo(kompetenz: String, tipps: Tipp[]): Observable<Tipp[]> {
    return this.http.post<Tipp[]>(this.apiUrl + '/filter2/' + kompetenz, tipps, httpOptions);
  }

  getkompetenz(tipps: Tipp[]): Observable<Kompetenz[]> {
    return this.http.post<Kompetenz[]>(this.apiUrl + 'kompetenz', tipps, httpOptions)
  }

  saveTippinputlog(tippinputlog: Tippinputlog): Observable<Tippinputlog> {
    return this.http.post<Tippinputlog>(this.apiUrl + '/log', tippinputlog, httpOptions)
  }

}
