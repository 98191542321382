import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { MessengerComponent } from './messenger/messenger.component';
import { SuretocloseDialogService } from './suretoclose-dialog.service';
import { ConfirmDialog } from './confirm-dialog/confirm-dialog';
import { MatDialogRef, MatDialog } from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<MessengerComponent> {
  dialogRef: MatDialogRef<ConfirmDialog>;

  constructor(public dialogService: SuretocloseDialogService, public dialog: MatDialog, public translate: TranslateService) {}

  canDeactivate(component: MessengerComponent): Observable<boolean> | boolean {
    if (component.bypassDeactivateGuard) {
      return true;
    }
    let redirection: boolean;
    this.dialogRef = this.dialog.open(ConfirmDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.confirmMessage = this.translate.instant('sklls.coaching.messageexitcoaching');


    const confirmation = this.dialogRef.afterClosed();
    confirmation.subscribe(result => {
      redirection = result;
      if(result) {
        // do confirmation actions
        component.deleteUserEntries();
      }
      this.dialogRef = null;
    });
    return confirmation;
    //return this.dialogRef;
    /*const confirmation = this.dialogService.confirm(
      // tslint:disable-next-line:max-line-length
      'Bist du sicher, dass du das Coaching verlassen willst? Deine Eingaben gehen verloren und du kannst das Coaching NICHT zu einem späteren Zeitpunkt fortsetzen'
    );
    confirmation.subscribe(event => (redirection = event));
    if (redirection) {
      component.deleteUserEntries();
    }
    return confirmation;*/
    /*if(this.dialogService.confirm("Wenn Du die Seite verlässt, gehen deine bisherigen Eingaben verloren. Bist Du sicher?")){
      component.deleteUserEntries()
      return true
    }else { return false }*/
  }
}
