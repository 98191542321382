import { Component, OnInit, DoCheck } from "@angular/core";
import { JoyrideService } from "ngx-joyride";
import { AppService } from "../app.service";
import { MENUPOINTS } from "../sidenav-menu/all-menupoints";
import { ɵɵelement } from "@angular/core";
import { environment } from "src/environments/environment";
import { MatIconRegistry } from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";
import { SecurityService } from "../auth/security-service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, DoCheck {
  ENV = environment;
  menuPoints = MENUPOINTS;
  info: any;
  constructor(
    private readonly joyrideService: JoyrideService,
    private appService: AppService,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private securityService: SecurityService,
    private domSanitizer: DomSanitizer
  ) {this.matIconRegistry.addSvgIcon("calendar", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/calendar.svg'))
                         .addSvgIcon("addressbook", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/addressbook.svg'))
                         .addSvgIcon("rocket", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/rocket.svg'));}

  ngOnInit() {
    this.info = {
      resilienceModel: this.securityService.hasResilienceModel()
    }
  }

  ngDoCheck() {
    this.changeColor();
  }
  changeColor() {
    this.menuPoints.forEach(e => {
      if (this.appService.header != this.translate.instant('sklls.sidenav.coaching')) {
        document
          .getElementsByName("coaching")
          .forEach(
            element => (
              (element.style.color = "#DC852A"),
              (element.style.backgroundColor = "#2D2D2E"),
              (element.style.paddingBottom = "1px")
            )
          );
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.journey')) {
        document
          .getElementsByName("journey")
          .forEach(
            element => (
              (element.style.color = "#DC852A"),
              (element.style.backgroundColor = "#2D2D2E"),
              (element.style.paddingBottom = "1px")
            )
          );
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.journal')) {
        document
          .getElementsByName("journal")
          .forEach(
            element => (
              (element.style.color = "#DC852A"),
              (element.style.backgroundColor = "#2D2D2E"),
              (element.style.paddingBottom = "1px")
            )
          );
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.progress')) {
        document
          .getElementsByName("progress")
          .forEach(
            element => (
              (element.style.color = "#DC852A"),
              (element.style.backgroundColor = "#2D2D2E"),
              (element.style.paddingBottom = "1px")
            )
          );
      }
      if (this.appService.header != this.translate.instant('sklls.sidenav.resilience')) {
        document
          .getElementsByName("resilienceTest")
          .forEach(
            element => (
              (element.style.color = "#DC852A"),
              (element.style.backgroundColor = "#2D2D2E"),
              (element.style.paddingBottom = "1px")
            )
          );
      }
      switch (this.appService.header) {
        case this.translate.instant('sklls.sidenav.journal'):
          document
            .getElementsByName("journal")
            .forEach(
              element => (
                (element.style.color = "#FFF"),
                (element.style.backgroundColor = "#494C4D"),
                (element.style.paddingBottom = "1px")
              )
            );
          break;
        case this.translate.instant('sklls.sidenav.journey'):
          document
            .getElementsByName("journey")
            .forEach(
              element => (
                (element.style.color = "#FFF"),
                (element.style.backgroundColor = "#494C4D"),
                (element.style.paddingBottom = "1px")
              )
            );
          break;
        case this.translate.instant('sklls.sidenav.coaching'):
          document
            .getElementsByName("coaching")
            .forEach(
              element => (
                (element.style.color = "#FFF"),
                (element.style.backgroundColor = "#494C4D"),
                (element.style.paddingBottom = "1px")
              )
            );
          break;
        case this.translate.instant('sklls.sidenav.progress'):
          document
            .getElementsByName("progress")
            .forEach(
              element => (
                (element.style.color = "#FFF"),
                (element.style.backgroundColor = "#494C4D"),
                (element.style.paddingBottom = "1px")
              )
            );
          break;
          case this.translate.instant('sklls.sidenav.resilience'):
          document
            .getElementsByName("resilienceTest")
            .forEach(
              element => (
                (element.style.color = "#FFF"),
                (element.style.backgroundColor = "#494C4D"),
                (element.style.paddingBottom = "1px")
              )
            );
          break;
      }
    });
  }
}