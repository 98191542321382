import { Injectable } from "@angular/core";
import { Tipp, Tippinputlog, Kompetenz } from "./tipp";

@Injectable({
  providedIn: "root"
})
export class TippService {

  initializerData = new Tippinputlog();
  initializerStepHistory = [];
  previousStep : number[] = new Array<number>();
  tmpTippArrayBack: Tipp[] = new Array<Tipp>();
  tippArray: Tipp[];
  ichAndere: String;
  needHelp: String;
  tippchoice: any;
  kompetenz: Kompetenz;
  kompetenzen: Kompetenz[] = new Array<Kompetenz>();
  verb: string;
  noun: string;
  verbandnoun: string[] = new Array<string>();

  constructor() {}

  clear() {
    this.initializerData = new Tippinputlog();
    this.initializerStepHistory = [];
    this.previousStep = new Array<number>();
    this.tmpTippArrayBack = new Array<Tipp>();
    this.tippArray = undefined;
    this.ichAndere = undefined;
    this.needHelp = undefined;
    this.tippchoice = undefined;
    this.kompetenz = undefined;
    this.kompetenzen = new Array<Kompetenz>();
    this.verb = undefined;
    this.noun = undefined;
    this.verbandnoun = new Array<string>();
  }

}
