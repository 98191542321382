import { Component, OnInit } from '@angular/core';
import { title } from 'process';
import { SecurityService } from '../auth/security-service';
import { ResilienceService } from '../services/resilience-service';
import { Result } from './result';
import { CompetencyResult } from './competencyResult';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-resilience-test',
  templateUrl: './resilience-test.component.html',
  styleUrls: ['./resilience-test.component.scss']
})
export class ResilienceTestComponent implements OnInit {

  ENV = environment;
  info: any;
  result = new Array<any>();
  options: any;
  spiderData: any;
  // TODO add more colors?
  colors: string[] = ['rgb(221, 239, 3)', 'rgb(6, 35, 55)', 'rgb(0, 95, 124)', 'rgb(199, 200, 199)', 'rgb(26, 60, 83)'];
  riskText = new Array<any>();
  strengthText = new Array<any>();
  potentialText = new Array<any>();
  horizontalBarData: any;
  horizontalOptions: any;
  totalScore: any;

  constructor(private securityService: SecurityService,
              private resilienceService: ResilienceService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.info = {
      resilienceModel: this.securityService.hasResilienceModel()
    }
    this.resilienceService.getResilienceResult().subscribe(
      testResults => {
        this.result = testResults;
        if(this.result[0] != null) {
        this.showResultTexts(this.result[0].competencyResults);
        this.spiderData = {
          // labels are all the same so we take the first test results labels, they are ordered in BE my competencyId, so they are always in same order
          labels: this.createLabels(this.result[0].competencyResults), 
          datasets: this.createDataSets(this.result)
        }
        this.options = {
          legend: {
            position: 'bottom'
         },
          scale: {
            ticks: {
              beginAtZero: true,
              min: 0,
              // possibleMaxResult are all the same so we can take the first one
              max: this.result[0].competencyResults[0].possibleMaxResult,
              stepSize: 5
            },
            pointLabels: {
              fontSize: 12,
              fontColor: 'rgb(6, 35, 55)',
              callback: function(label) {
                if (/\s/.test(label)) {
                  return label.split(" ");
                } else {
                  return label;
                }              
              }
          },
          legend: {
            labels: {
              fontFamily: 'Roboto',
              fontSize: 15
            }
         },
           gridLines: {
            color: 'rgb(6, 35, 55)'
           },
           angleLines: {
            color: 'rgb(6, 35, 55)'
           }
          }
        }
      }
      }
    )
    this.resilienceService.getTotalScoreResilienceResults().subscribe(
      totalScoreResults => {
      this.totalScore = totalScoreResults;
      if(this.totalScore != null) {
      this.horizontalBarData = {
        labels: [this.translate.instant('sklls.analytics.resiliencepercentage')],
        datasets: [
            {
              label: this.translate.instant('sklls.resilience.totalScore'),
              backgroundColor: ['#062337'],
              data: this.totalScore
            }
  
        ]
    };
  
    this.horizontalOptions = {
        indexAxis: 'y',
        plugins: {
            legend: {
                labels: {
                    color: '#black'
                }
            }
        },
        legend: {
          position: 'bottom'
       },
        scales: {
            xAxes: [{
                ticks: {
                color: '#black',
                min: 0,
                max: 100,
                stepSize: 10
                }
            }],
            yAxes: {
                ticks: {
                    color: '#black',
                }
            },
            legend: {
              labels: {
                fontFamily: 'Roboto',
                fontSize: 15
              }
           }
        }
      }
    }
    }
    )
  }

  createLabels(competencyResults: CompetencyResult[]) {
    const labels = new Array<string>();
    for (let i = 0; i < competencyResults.length; i++) {
      labels.push(competencyResults[i].title);
    }
    return labels;
  }

  createDataSets(results: Result[]) {
    const dataSets = new Array<any>();
    for (let i = 0; i < results.length; i++) {
      var color = this.colors[i % this.colors.length];
      dataSets.push(
        {
          label: this.result[i].createdat,
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: color,
          data: this.createSingleDataSet(this.result[i].competencyResults)
        }
      );
    }
    return dataSets;
  }
  
  createSingleDataSet(competencyResults: CompetencyResult[]) {
    const dataSet = new Array<number>();
    for (let i = 0; i < competencyResults.length; i++) {
      dataSet.push(competencyResults[i].result);
    }
    return dataSet;
  }

  showResultTexts(competencyResults: CompetencyResult[]) {
    for (let i = 0; i < competencyResults.length; i++) {
      if (competencyResults[i].result <= 9) {
      this.riskText.push('<h3>' + competencyResults[i].title + '</h3>' + this.translate.instant('sklls.competency.' + competencyResults[i].competencyId + '.risk.description'));
    } else if (competencyResults[i].result >= 10 && competencyResults[i].result <= 12) {
      this.potentialText.push('<h3>' + competencyResults[i].title + '</h3>' + this.translate.instant('sklls.competency.' + competencyResults[i].competencyId + '.potential.description'));
    } else if (competencyResults[i].result >= 13 && competencyResults[i].result <= 15) {
      this.strengthText.push('<h3>' + competencyResults[i].title + '</h3>' + this.translate.instant('sklls.competency.' + competencyResults[i].competencyId + '.strength.description'));
    }}
  }
}
