import { PathService } from '../messenger/path.service';
import { TokenStorageService } from '../auth/token-storage.service';
import { Component, OnInit } from '@angular/core';
import { ChallengesElement } from './challengesElement';
import { JournalService } from '../journal/journal-service.service';
import { ProgressService } from '../progress/progress.service';
import { JournalEntry } from '../journal/journal-entry';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../app.service';
import { environment } from 'src/environments/environment';

export interface Tile {
text: string;
fotosUrl: string;
}

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss']
})
export class ChallengeComponent implements OnInit {
    ENV = environment;
    info: any;
    startDate: Date;
    activesince: number;
    challengesElemente: ChallengesElement[] = [];
    userJourney: ChallengesElement[] = [];

    constructor(private token: TokenStorageService,
      private pathservice: PathService, 
      private journalService: JournalService,
      private appService: AppService,
      private progressService: ProgressService,
      private matIconRegistry: MatIconRegistry,
      private domSanitizer: DomSanitizer
    ) {this.matIconRegistry.addSvgIcon("watch", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/watch.svg'))
                           .addSvgIcon("search", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/search.svg')); }

  ngOnInit() {
    this.journalService.getJournalEntries();
    this.info = {
      token: this.token.getToken() || this.token.getSessionToken(),
      fullname: this.token.getUsername() || this.token.getSessionUsername(),
    };
    this.pathservice.getJourney().subscribe(
      data => {
        this.userJourney = data;
      }
    )
    this.pathservice.getChallenge().subscribe(
      data => {
        this.challengesElemente = data;
      }
    );
    
  }

  logout() {
    this.token.signOut();
    window.location.reload();
  }

  startPath(id: number, titel: string, type: number) {
    this.pathservice.resetStepNo();
    this.pathservice.setPathId(id);
    this.pathservice.setPathType(type);
    this.pathservice.setPathTitle(titel);
  }

  starttest() {
    this.pathservice.getChallenge();
  }


setCompleted(id: number) {
  var i =0
  let journalEntry = new JournalEntry;
  for(i=0;i< this.journalService.journalentriesDto.length; i++){
    if(id == this.journalService.journalentriesDto[i].journalEntry.coachingpathid){
      journalEntry= this.journalService.journalentriesDto[i].journalEntry;
    }
  }
  journalEntry.completed = true;
    this.journalService.updateJournalEntries(journalEntry);
    this.progressService.addPassedChallengesToProgress();

}
}
