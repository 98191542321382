import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtResponse } from './jwt-response';
import { AuthLoginInfo } from './login-info';
import { SignUpInfo } from './signup-info';
import { environment } from './../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl =  environment.apiUrl + 'auth/signin';
  private signupUrl = environment.apiUrl + 'auth/signup';
  private tutorialUrl = environment.apiUrl + 'auth/tutorial';
  private registerUrl = environment.apiUrl + 'auth/register';
  private privacyUrl = environment.apiUrl + 'auth/privacy';

  constructor(private http: HttpClient) {
  }

  attemptAuth(credentials: AuthLoginInfo): Observable<JwtResponse> {
    return this.http.post<JwtResponse>(this.loginUrl, credentials, httpOptions);
  }

   getTutorial(): Observable<number> {
    return this.http.get<number>(this.tutorialUrl);
  }

  signUp(info: SignUpInfo): Observable<string> {
    return this.http.post<string>(this.signupUrl, info, httpOptions);
  }

  register(info: SignUpInfo): Observable<string> {
    return this.http.post<string>(this.registerUrl, info, httpOptions);
  }

  agreeToPrivacy (agree: Boolean): Observable<string> {
    return this.http.post<string>(this.privacyUrl, agree, httpOptions);
  }
}
