import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ContentsElement } from './ContentsElement';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({
    providedIn: 'root',

})
export class ContentsDataService {
  apiUrl = environment.apiUrl + 'public/' + 'contents';
  httpOptions = {  headers: new HttpHeaders({ 'Content-Type': ['application/json', 'arraybuffer'] })};
  constructor(private http: HttpClient) { }

  getContents(typ: String, language: string): Observable<ContentsElement> {
    const params = new HttpParams().set('language', language);
    return this.http.get<ContentsElement>(this.apiUrl + '/typ/' + typ,  {params});
  }
}
