import { ProgressDto } from './progressDto';
import { Component, OnInit } from '@angular/core';
import { Progress, Progressbar } from './Progress';
import { ProgressService } from './progress.service';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { SecurityService } from '../auth/security-service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {
  ENV = environment;
  //progress: Progress;
  progressDto: ProgressDto;
  progressbar: Progressbar;
  expLevel: String;
  nextExpLevel: String;
  summe: number;
  progresspoints: number;
  info: any;

  constructor(private progressServise: ProgressService,
              private appService: AppService,
              private securityService: SecurityService) {}

  ngOnInit() {
    this.getProgressDto();
    this.getProgressbar();
    this.info = {
      resilienceModel: this.securityService.hasResilienceModel()
    }

  }

  getProgressDto(): void {
      this.progressDto = this.progressServise.getProgressDto().subscribe(data => {
      this.progressDto = data;
      this.progressServise.setProgress(data);
    //  this.progressbar = this.progressServise.getProgressbar();
    //  this.expLevel = this.progressServise.getExpLevel();
     // this.nextExpLevel = this.progressServise.getNextExpLevel();
      this.getProgressbar();
      this.setProgresspoints();
     } );
  }

  getProgressbar(): void {
    this.progressbar =  new Progressbar();

  }
  setProgresspoints() {
    if (this.progressDto.progressPoints < 101) {
      this.progresspoints = (this.progressDto.progressPoints) / 10;
    } else {
      this.progresspoints = 10;

    }
  }

}

