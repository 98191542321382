import { Injectable } from "@angular/core";
import { TokenStorageService } from "./token-storage.service";

@Injectable({
    providedIn: 'root'
  })
export class SecurityService {

    constructor(private tokenStorage: TokenStorageService) {}
    
    hasRole(userRole: string) {
      let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      return decodedJWT["Authorities"] === userRole;
    }

    hasUserRole() {
      let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      return decodedJWT["Authorities"].includes("ROLE_USER");
    }

    hasAdminRole() {
      let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      return decodedJWT["Authorities"].includes("ROLE_ADMIN");
    }

    isExpired() {
    let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
    const jwtToken = JSON.parse(window.atob(token.split('.')[1])).exp;
    return (Math.floor(new Date().getTime() / 1000)) >= jwtToken;
    }

    isCoachingUser() {
      let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      return decodedJWT["CompetencyModel"] === 1;
    }
    
    hasResilienceModel() {
      let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      return decodedJWT["CompetencyModel"] === 2;
    }
    
     hasAuditModel() {
      let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      return decodedJWT["CompetencyModel"] === 3;
    }

    isUnternehmenAllowsGptUsage() {
      let token = this.tokenStorage.getToken() || this.tokenStorage.getSessionToken();
      let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
      return decodedJWT["UnternehmenAllowsGptUsage"] === true;
    }
}