import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material";
import { TutorialDataService } from "./tutorial.data.service";
import { Tutorial } from "./tutorial";
import { ProgressService } from '../progress/progress.service';
import { PopupDto } from '../popup-widget/popupDto';
import { PopupDialogComponent } from '../popup-dialog/popup-dialog.component';
import { AuthService } from '../auth/auth.service';
import {HtmlService} from '../html.service';

@Component({
  selector: "tutorial-main-dialog",
  templateUrl: "./tutorial.dialog.html",
  styleUrls: ["./tutorial.dialog.component.scss"]
})
export class TutorialDialog implements OnInit {
  private tippId: number;
  public tutorial: Tutorial;
  private tutorialList: Tutorial[];
  popupDto: PopupDto;

  ngOnInit(): void {
    this.tutorial = this.getTutorial();
    this.tutorialList = this.getTutorialList();
  }

  constructor(
    public dialogRef: MatDialogRef<TutorialDialog>,
    private progressService: ProgressService,
    public dialog: MatDialog,
    private authService: AuthService,
    private htmlservice: HtmlService,
    public tutorialService: TutorialDataService
  ) {
    this.tippId = 1;
  }

  back() {
    if (this.tippId != 1) {
      this.tippId = this.tippId - 1;
      this.tutorial = this.getTutorialById(this.tippId);
    } else {
      this.tutorial = this.getTutorialById(1);
    }
  }

  next() {
    if (this.tippId != 7) {
      this.tippId = this.tippId + 1;
      this.tutorial = this.getTutorialById(this.tippId);
    } else {
      this.tutorial = this.getTutorialById(7);
    }
  }

  getTutorial(): Tutorial {
    this.tutorialService.getTutorialById(this.tippId).subscribe(data => {
      this.tutorial = data;
      this.tippId = data.id;
      return this.tutorial;
    });

    return this.tutorial;
  }

  getTutorialById(id: number): Tutorial {
    this.tutorialService.getTutorialById(id).subscribe(data => {
      this.tutorial = data;
      this.tippId = data.id;
      return this.tutorial;
    });

    return this.tutorial;
  }

  getTutorialList(): Tutorial[] {
    this.tutorialService.getTutorialList().subscribe(data => {
      this.tutorialList = data;
      return this.tutorialList;
    });
    return this.tutorialList;
  }

  closeDialog(finished : boolean) {
    if (finished) {
      this.authService.getTutorial().subscribe(data => {
        if (data != 1) {
          this.progressService.addTutorialToProgress();
          setTimeout(() => this.openProgressDialog(), 1000);
        }
      });
    }
    this.dialogRef.close();
  }

  openProgressDialog() {
    this.popupDto= this.progressService.popupDto;
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      disableClose: true,
      data: { hallo: this.popupDto.title,
        levelTitle: this.popupDto.levelTitle,
        bild: this.popupDto.bildLevel,
        bild2: this.popupDto.bildPopup,
        text: this.popupDto.textPopup,
        buttonJa: this.popupDto.buttonA,
        progressPoints: this.popupDto.progressPoints
      }
    });
    /*dialogRef.afterClosed().subscribe( () => {
      this.progressService.showdialog= false;
      this.pathservice.propagate = true;
    });*/
  }

  toHtmlText(text: string) {
    return this.htmlservice.convertToHtml(text);
  }
}
