import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { SignUpInfo } from "../auth/signup-info";
import { ContentsDataService } from "../register/register.service";
import { ContentsElement } from "../register/ContentsElement";
import { environment } from "src/environments/environment";
import { htmlDataService } from "../html.data.service";
import { HtmlService } from "../html.service";
import { AppService } from '../app.service';
import { TokenStorageService } from "../auth/token-storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PathService } from "../messenger/path.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  ENV = environment;
  form: any = {};
  registerInfo: SignUpInfo;
  isRegister = false;
  isRegisterFailed = false;
  errorMessage = "";
  contents: ContentsElement;

  id: number;
  typ: string;
  title: string;
  description: string;
  details: string;
  loading: boolean;

  constructor(
    private authService: AuthService,
    private contentsDataService: ContentsDataService,
    private htmldataservice: htmlDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pathservice: PathService,
    private htmlService: HtmlService,
    private appService: AppService,
    private tokenStorage: TokenStorageService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.htmldataservice.getVocabulary().subscribe(data => {
      this.htmlService.vocabulary = data;
      this.contentsDataService.getContents("Datenschutz", this.appService.selectedLanguage).subscribe(data => {
        this.id = data.id;
        this.typ = data.typ;
        this.title = data.title;
        this.description = data.description;
        this.details = data.details;
        this.loading = false;
      });
      this.htmlService;
    });
  }

  onSubmit() {
    console.log(this.form);
    if (this.form.password.localeCompare(this.form.passwordRepeat) != 0) {
      console.log("passwords dont match");
      this.errorMessage = "Passwords don't match!";
      this.isRegisterFailed = true;
    } else {
      this.registerInfo = new SignUpInfo(
        this.form.username,
        this.form.email,
        this.form.password,
        this.form.privacyaggrement,
        this.tokenStorage.getLanguange() || this.tokenStorage.getSessionLanguange()
      );
      this.authService.register(this.registerInfo).subscribe(
        data => {
          console.log(data);
          this.isRegister = true;
          this.isRegisterFailed = false;
        },
        error => {
          console.log(error);
          this.errorMessage = error.error.message;
          this.isRegisterFailed = true;
        }
      );
       this.activatedRoute.queryParams.subscribe(params => {
        let coachingpathid = params['coachingpathid'];
      if(coachingpathid != null && this.isRegister == true) {
         this.router.navigateByUrl('/login?coachingpathid=' + coachingpathid);
      }
      })
    }
  }

  onChangeLanguage(lang){
    this.appService.changeLanguage(lang);
    this.tokenStorage.setLanguange(lang);
    this.tokenStorage.setSessionLanguange(lang);
    this.contentsDataService.getContents("Datenschutz", this.appService.selectedLanguage).subscribe(data => {
      this.id = data.id;
      this.typ = data.typ;
      this.title = data.title;
      this.description = data.description;
      this.details = data.details;
      this.loading = false;
    });
  }
}
