import { JournalEntry } from '../journal/journal-entry';

export class JournalEntryDetailsElement {
  title: string;
  text: string;
  journalEntryId: JournalEntry;
  id: number;
  typ: string;
  tippid: number;
  editoractive = false;
  pathTypeId = new Type();
}
  export class  Type {
    id: number;
   name: string;
}
