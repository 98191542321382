import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JournalEntryDetailsElement } from './journal-entry-details-element'
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({
    providedIn: 'root',

})
export class JournalDetailsDataService {
  apiUrl = environment.apiUrl + 'journal/data';
  apiUrlTransformTo = environment.apiUrl + 'transform/';
  httpOptions = {  headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

  constructor(private http: HttpClient) { }

  getEntryDetails(): Observable<JournalEntryDetailsElement[]> {
    return this.http.get<JournalEntryDetailsElement[]>(this.apiUrl + '/user')
  }

  updateDetails(journalDetails: JournalEntryDetailsElement): Observable<JournalEntryDetailsElement> {
    return this.http.put<JournalEntryDetailsElement>(this.apiUrl, journalDetails, httpOptions);
  }

  addDetails(journalDetails: JournalEntryDetailsElement): Observable<JournalEntryDetailsElement> {
    return this.http.post<JournalEntryDetailsElement>(this.apiUrl, journalDetails, httpOptions);
  }

  transformToDetails(endStepid: number): Observable<JournalEntryDetailsElement> {
    return this.http.get<JournalEntryDetailsElement>(this.apiUrlTransformTo + endStepid);
  }

  getJournalEntryDetail(journalDetailId: number): Observable<JournalEntryDetailsElement> {
    return this.http.get<JournalEntryDetailsElement>(this.apiUrl + journalDetailId, httpOptions);
  }

  addEvaluationDetails(journalDetails: JournalEntryDetailsElement, endStepid: number): Observable<JournalEntryDetailsElement> {
    return this.http.post<JournalEntryDetailsElement>(this.apiUrl + '/evaluation/' + endStepid, journalDetails, httpOptions);
  }

}
