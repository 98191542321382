import { Injectable } from '@angular/core';
import { Progress, Progressbar } from './Progress';
import { ProgressDataService } from './progress.data.service';
import { PopupDto } from '../popup-widget/popupDto';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private expLevel: String;
  private nextExpLevel: String;
  private summe: number;
  private points = 0;
  private levelmin: number;
  private levelmax: number;
  private progressbar = new Progressbar();
  private progress: Progress;
  popupDto: PopupDto;
  showdialog: boolean = false;

  constructor(private progressdataservice: ProgressDataService, private router: Router) { }

  // Progress aus DB holen und in progress speichern
 /*  getProgress(): any {
    return this.progressdataservice.getProgress();
  } */

  getProgressDto(): any {
    return this.progressdataservice.getProgressDto();
  }

  setProgress(p: Progress) {
    this.progress = p;
  }

  // updateProgress to DB --- ohne subscribe funktioniert nicht!
  addCoachedTasksToProgress(): void {
    this.progressdataservice.addProgress(0).subscribe(data => {
      this.popupDto = data;
      this.showdialog= true;
    });
  }

  addAppliedSolutionsToProgress(): void {
    this.progressdataservice.addProgress(1).subscribe(data => {
      this.popupDto = data;
    });
   }

  addEvaluatedSolutionsToProgress(): void {
    this.progressdataservice.addProgress(2).subscribe(data => {
      this.popupDto = data;
      this.showdialog= true;
      this.router.navigateByUrl('main/journal');
    });
  }

  addPassedChallengesToProgress(): void {
    this.progressdataservice.addProgress(3).subscribe(data => {
      this.popupDto = data;
      this.showdialog= true;
      this.router.navigateByUrl('main/journal');
    });
  }

  addCreatedJournalEntriesToProgress(): void {
    this.progressdataservice.addProgress(4).subscribe(data => {
      this.popupDto = data;
    });

  }

  addTutorialToProgress(): void {
    this.progressdataservice.addProgress(5).subscribe(data =>{
      this.popupDto = data;
    });
  }

}
