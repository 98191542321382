import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MainComponent } from './../main/main.component';
import { UserInput } from "../user/userInput";
import { StepDto } from "./stepDto";
import { PathService } from "./path.service";
import { Answer, DoppelAnswer } from "./answer-entry";
import { SLIDERVALUE } from "../mock-evaluation-answer";
import { MatDialog, MatIconRegistry } from "@angular/material";
import { HtmlService } from "../html.service";
import { StepDataService } from "./step.data.service";
import { htmlDataService } from "../html.data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Tipp, Kompetenz, Tippinputlog } from "./tipp";
import { HintDataService } from "./hint.data.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { TippService } from "./tipp.service";
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
import { ChallengesElement } from '../challenge/challengesElement';
import { DomSanitizer } from '@angular/platform-browser';
import { ChallengesJourney } from '../challenge/challengesJourney';
import { TimelineJourney } from '../challenge/timelineJourney';
import { Message, MessageType } from './message';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { environment } from 'src/environments/environment';
import { SecurityService } from '../auth/security-service';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent implements OnInit, AfterViewChecked {
  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  @ViewChild('scrollbottom', {static: false}) scrollBottom: ElementRef;

  ENV = environment;
  choice: string;
  tmp = false; // lolwut
  tippfound: boolean;
  textalg: String;
  tippArray: Tipp[];
  errorMessage = '';
  header: String;
  avgDuration: number;
  challengesElemente: ChallengesElement[] = [];
  userJourney: ChallengesJourney[] = [];
  journeyTimeline: TimelineJourney[] = [];
  messages: Message[] = [];
  tipp: Tipp;
  tippinputlog: Tippinputlog = new Tippinputlog();
  tmpTippArray: Tipp[] = new Array<Tipp>();
  isTippInitializer = false;
  isTipp = false;
  additionalTippText: String;
  additionalTippText2: String;
  hint: Tipp;
  aufgabeTitel: string;
  intro: Answer[];
  shortdescription: Answer[];
  backgroundinfo: string;
  howitworks: Answer[];
  tryit: Answer[];
  examples: Answer[];
  moreInfo: string;
  displayedTippId: number;
  title: string;

  constructor(
    private stepDataService: StepDataService,
    public dialog: MatDialog,
    public pathservice: PathService,
    private htmlservice: HtmlService,
    private htmldataservice: htmlDataService,
    private router: Router,
    private tippDataService: HintDataService,
    private tippService: TippService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private mainComponent: MainComponent,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private securityService: SecurityService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon("watch", this.domSanitizer.bypassSecurityTrustResourceUrl('assets/watch.svg'));
  }
  state: any;
  // Für Navigation
  navfunction: Function;
  navParameter: string[];
  skippable: boolean;
  skippable261: boolean;
  bypassDeactivateGuard: boolean;
  modlastStep: StepDto;
  required = false;
  // Für Templates
  answerentries: Answer[] = [new Answer()];
  answerentriesMulti: DoppelAnswer[] = [new DoppelAnswer()];
  isDoubleAnswer: boolean = false;
  slidervalues = SLIDERVALUE;
  answers: Answer[] = [new Answer()];
  coatchtextanswers: Answer[];
  coachtexts: Answer[] = [];
  helptext: Answer[];
  sliderDeactivated: boolean;
  templateId: number;
  options: FormGroup;
  submitted = false;
  myplaceHolder = "...";
  defaultAnswers = true;
  removedTippGefunden = false;
  progressbar: any = {
    value: 70,
    color: "primary",
    mode: "determinate",
    bufferValue: 100
  };
  infobuttonaktiv = false;
  // Für DB
  private userInput: UserInput;
  stepdto: StepDto;
  // for animation
  isTyping: boolean = true;
  index: number = 0;
  durations: number[] = [];

  // for chat history / scroll function
  messageId: number = 0;
  inputActive: boolean = false;
  ORIGINAL_STEP_IDS = "originalStepIds";

  ngDoCheck() {
    this.activatedRoute.queryParams.subscribe(params => {
      let coachingpathid = params['coachingpathid'];
      if (this.router.url === '/main/path' || this.router.url === '/main/path?coachingpathid=' + coachingpathid) {
        this.header = this.appService.title;
        let i = 0;
        if(this.pathservice.getPathId() === 91) {
          this.avgDuration = 5;
        } 
        for (i = 0; i < this.journeyTimeline.length; i++) {
          if (this.journeyTimeline[i].title == this.header) {
            this.avgDuration = this.journeyTimeline[i].avgDurationInMinutes;
            break;
          } if(this.journeyTimeline[i].title != this.header && this.pathservice.getPathId() != 91) {
            this.avgDuration = 15;
            break;
          }
        }
        for (i = 0; i < this.userJourney.length; i++) {
          if (this.userJourney[i].title == this.header) {
            this.avgDuration = this.userJourney[i].avgDurationInMinutes;
            break;
          }
        }
        for (i = 0; i < this.challengesElemente.length; i++) {
          if (this.challengesElemente[i].title == this.header) {
            this.avgDuration = this.challengesElemente[i].avgDurationInMinutes;
            break;
          }
        }
      } else {
        this.header = null;
      }
    })
  }

  // Erster Step des angeklickten Pfades wird geladen
  ngOnInit() {
    this.init();
  }
  
  private init(): void {
    console.log("init() is called");
    window.localStorage.removeItem(this.ORIGINAL_STEP_IDS);
    window.sessionStorage.removeItem(this.ORIGINAL_STEP_IDS);
    this.pathservice.loadSpinner = false;
    this.pathservice.loading = false;
    this.pathservice.getJourney().subscribe(
      data => {
        this.userJourney = data;
      }
    )
    this.pathservice.getChallenge().subscribe(
      data => {
        this.challengesElemente = data;
      }
    )
    this.pathservice.getInitialJourney().subscribe(
      data => {
        this.journeyTimeline = data;
      }
    )
    this.htmldataservice.getVocabulary().subscribe(data => {
      this.htmlservice.vocabulary = data;
      this.bypassDeactivateGuard = !this.isQuickChallenge();
      if (this.pathservice.pathType != null) {
        if (this.pathservice.newPathType()) {
          this.tippService.clear(); // clear tippService saved data
          this.pathservice.additionalTippText = null; // clear user entered texts inside tipp
          this.getFirstStep();
        } else {
          if (this.state != undefined && this.state.returnFromTipp) {
            this.wiederEinstiegAfterTipp(); // get's next step after Tipp
          } else {
            this.wiederEinstieg(); // get's last userinput
          }
        }
      } else {
        // Wird /path geöffnet, ohne dass ein Pfad hinterlegt ist (bspw. durch eintippen der URL) wird zurück auf /main dirigiert
        this.pathservice.router.navigateByUrl("/main/journey");
      }
    });
    this.htmlservice;
    this.options = this.fb.group({
      color: "primary"
    });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
    if(this.userInput != null && this.userInput.predecessorstepid.id != 0 && !this.isTyping && this.answerentries.length < 7 && this.templateId != 1) {
      this.viewScroll();
    } 
    
  }

  getFirstStep(): void {
    console.log("getFirstStep() is called");
    this.stepdto = this.pathservice.getFirstStepDTO().subscribe(data => {
      this.stepdto = data;
      console.log("stepdto.id = " + this.stepdto.id);
      this.pathservice.setPathId(this.stepdto.pathId);
      this.pathservice.setPathIdStack();
      if (
        this.stepdto.pathTitel !== undefined &&
        this.stepdto.pathTitel !== null &&
        this.stepdto.pathTitel !== ""
      ) {
        this.pathservice.setPathTitle(this.stepdto.pathTitel);
      }
      this.pathservice.setStepDto(data);
      this.preparePostUserInput();
      this.userInput.predecessorstepid.id = 0;
      this.tryGettingStep();
      window.localStorage.removeItem(this.ORIGINAL_STEP_IDS);
      window.sessionStorage.removeItem(this.ORIGINAL_STEP_IDS);
    });
  }

  startTypingAnimation() {
    this.durations = [];
    this.coachtexts.forEach((element) => {
      let duration = this.getTypingAnimationDuration(element.answer);
      this.durations.push(duration);
    });
    /* get the duration of first bubble and put it in second place */
    let firstDuration = this.durations.shift();
    let message = new Message();
    message.answers = this.answerentries;
    if(this.userInput.predecessorstepid.id == 0) {
      /* typing dots shouldn't be shown on the very first step */
      this.durations.unshift(0, firstDuration);
    } else if (this.templateId == 1 && this.inputActive) {
     this.durations.unshift(3000, firstDuration);
     this.durations.pop();
    } else if (this.templateId == 1 && !this.inputActive) {
      this.durations.unshift(0, firstDuration);
      this.durations.pop();
    } else if(this.templateId != 1 && this.inputActive) {
      this.durations.unshift(3000, firstDuration);
    } else if(this.templateId != 1 && !this.inputActive) {
      this.durations.unshift(0, firstDuration);
    }
    this.setTypingAnimation(this.durations);
  }

  async setTypingAnimation(durations: number[]) {
    this.index = 0;
    for await (const duration of durations) {
      this.isTyping = true;
      await new Promise(f => setTimeout(f, duration));
      this.isTyping = false;
      this.index++;
    }
    if (this.templateId === 1) {
      this.endStep();
    }
  }

  getTypingAnimationDuration(text: string) {
    let numWords = text.split(' ').length;
    return numWords * 240;
  }

  private scrollToBottom(): void {
    try {
      this.scrollFrame.nativeElement.scrollTo({ left: 0, top: this.scrollFrame.nativeElement.scrollHeight, behavior: 'smooth' });
    } catch (err) {
      console.log(err);
      return;
    }
  }

  viewScroll(): void {
    this.scrollBottom.nativeElement.scrollIntoView(true, {behavior: "smooth"});
  }

  wiederEinstieg() {
    this.stepdto = this.pathservice.getLetzteStepDTO(false).subscribe(data => {
      this.stepdto = data;
      this.pathservice.setStepDto(data);
      this.preparePostUserInput();
      this.userInput.predecessorstepid.id = this.stepdto.id;
      // manipulates the Userinput if the User had a Tipp to trigger specialnavigation
      if (this.pathservice.tippDisplayed /* && !this.state.backButtonFromInitializer */) {
        let userInputupdate: UserInput;
        this.bypassDeactivateGuard = false;
        // tslint:disable-next-line:no-shadowed-variable
        this.stepDataService.getUserInputDB(this.stepdto.id).subscribe(data => {
          userInputupdate = data;
          userInputupdate.inputcontent = data.inputcontent + "Tipp gefunden";
          this.stepDataService.addUserInput(userInputupdate).subscribe(() => {
            this.nextStepdto(this.stepdto.id);
          });
        });
      } else {
        this.nextStepdto(this.stepdto.id);
      }
    });
  }

  // get's next step after Tipp, even if User was already one or more steps
  wiederEinstiegAfterTipp() {
    this.stepdto = this.pathservice.getLetzteStepDTO(true).subscribe(data => {
      this.stepdto = data;
      this.pathservice.setStepDto(data);
      this.preparePostUserInput();
      this.userInput.predecessorstepid.id = this.stepdto.id;
      // manipulates the Userinput if the User had a Tipp to trigger specialnavigation
      if (this.pathservice.tippDisplayed) {
        let userInputupdate: UserInput;
        this.stepDataService.getUserInputDB(this.stepdto.id).subscribe(data => {
          userInputupdate = data;
          if (
            userInputupdate !== null &&
            userInputupdate.inputcontent !== null &&
            userInputupdate.inputcontent !== undefined
          ) {
            userInputupdate.inputcontent = data.inputcontent + "Tipp gefunden";
          }
          this.stepDataService.addUserInput(userInputupdate).subscribe(() => {
            this.nextStepdto(this.stepdto.id);
          });
        });
      } else {
        this.nextStepdto(this.stepdto.id);
      }
    });
  }

  // Holt den Step nach dem currrentStep
  nextStepdto(currentStep: number) {
    console.log("currentStep: " + currentStep);
    let stack: number[] = [];
    if (window.localStorage.getItem(this.ORIGINAL_STEP_IDS) != null) {
      stack = JSON.parse(window.localStorage.getItem(this.ORIGINAL_STEP_IDS));
    } else if (window.sessionStorage.getItem(this.ORIGINAL_STEP_IDS) != null) {
      stack = JSON.parse(window.sessionStorage.getItem(this.ORIGINAL_STEP_IDS));
    }
    console.log("stack: " + stack);
    let originalStepId = null;
    if (stack != null && stack.length > 0) {
      originalStepId = stack[0];
    }
    console.log("originalStepId: " + originalStepId);
    this.stepdto = this.pathservice.getNextOrOriginalStepDto(currentStep, originalStepId).subscribe(data => {
    this.stepdto = data;
    console.log("stepdto " + this.stepdto);
		
      // check if we need to jump out of this coaching completely or to jump to pathId
	  if (this.stepdto.jumpOut || this.stepdto.jumpToPathid != null) {
      console.log("jumpOut " + this.stepdto.jumpOut);
      console.log("jumpToPathid " + this.stepdto.jumpToPathid);
		  console.log("bypassDeactivateGuard: " + this.bypassDeactivateGuard);
		// we need to deactivate guard
		this.bypassDeactivateGuard = true;
		console.log("bypassDeactivateGuard: " + this.bypassDeactivateGuard);
		// then delete old user entries
		this.deleteUserEntries();
		console.log("deleted user entries");
		if (this.stepdto.jumpOut) {
      console.log("jump out to journey");
		  this.pathservice.resetPathId();
      this.pathservice.resetStepNo();
      this.pathservice.removePathIdFromStack();
      window.localStorage.removeItem(this.ORIGINAL_STEP_IDS);
      window.sessionStorage.removeItem(this.ORIGINAL_STEP_IDS);
      this.pathservice.router.navigateByUrl("/main/journey");
      return;
		} else {
	      // re-init component
        this.init();
        this.pathservice.router.navigateByUrl("/main/path?coachingpathid=" + this.stepdto.jumpToPathid);
		}
	  }
      
      // if an originalStepId is returned, then we need to store it locally
      if (this.stepdto.originalStepId != null) {
        stack.push(this.stepdto.originalStepId);
        console.log("added " + this.stepdto.originalStepId + " to stack: " + stack);
        window.localStorage.setItem(this.ORIGINAL_STEP_IDS, JSON.stringify(stack));
        window.sessionStorage.setItem(this.ORIGINAL_STEP_IDS, JSON.stringify(stack));
      }

      // clean local originalStepId if it is the currentStep (then we are back at the original path)
       if (stack != null && stack.length > 0 && this.stepdto.id == stack[stack.length - 1]) {
         // remove last originalStepId from stack
        stack.pop();
        console.log("removed " + this.stepdto.id + " from stack: " + stack);
        window.localStorage.setItem(this.ORIGINAL_STEP_IDS, JSON.stringify(stack));
        window.sessionStorage.setItem(this.ORIGINAL_STEP_IDS, JSON.stringify(stack));
      }
      if (this.stepdto.id !== 0 && this.stepdto.id !== -1) {
        if (
          this.stepdto.pathTitel !== undefined &&
          this.stepdto.pathTitel !== null &&
          this.stepdto.pathTitel !== ""
        ) {
          this.pathservice.setPathTitle(this.stepdto.pathTitel);
        }
        this.pathservice.setStepDto(data);
        this.tryGettingStep();
      } else if (this.stepdto.id === -1) {
        this.pathservice.setStepDto(data);
        this.textalg = this.stepdto.answervalues[0];
        console.log("Anliegen ", this.textalg);
        this.answerentries[0].answer = this.stepdto.answervalues[0];
        this.pathservice.tippDisplayed = false;
        this.templateId = this.stepdto.steptypeid;
        this.postUserInput();
      } else {
        this.bypassDeactivateGuard = true;
        this.pathservice.pathEnde(currentStep);
      }
    });
    this.infobuttonaktiv = false;
  }

  // Entfernt alle Nutzereingaben, sodass sie nicht ausversehen gespeichert werden.
  // Ruft dann neuen Step
  skip() {
    // tslint:disable-next-line:no-shadowed-variable
    this.answerentries.forEach(element => {
      element.answer = "";
    });
    this.pathservice.getStepDto(this.stepdto.skippto).subscribe(data => {
      this.stepdto = data;
      if (this.stepdto.id !== 0) {
        this.stepdto = data;
        this.pathservice.setStepDto(data);
        this.tryGettingStep();
      } else {
        this.pathservice.pathEnde(this.stepdto.skippto);
      }
    });
    this.infobuttonaktiv = false;
  }

  // Verarbeitet und speichert die Nutzereingaben und ruft den nextStep
  endStep() {
    const tempArrayUserAnswers = this.pathservice.deepcopyAnswerArray(
      this.answerentries
    );
    // removes empty answers except min answers if user added inputfield but didn't write anything.    }
    if (
      this.answerentries.length > this.stepdto.answermin &&
      this.templateId !== 6
    ) {
      for (let i = 0; i < this.answerentries.length; i++) {
        if (
          this.answerentries[i].answer === undefined ||
          this.answerentries[i].answer.trim() === ""
        ) {
          this.answerentries.splice(i, 1);
          i--;
        }
      }
    }

    this.skippable = false;
    if (!this.isTippInitializer && !this.isTipp) {
      if (this.templateId !== 1) {
        const tempArray = this.pathservice.deepcopyAnswerArray(
          this.answerentries
        );
        if (
          this.answerentries.length < this.stepdto.answermax + 1 &&
          this.answerentries.length > this.stepdto.answermin - 1
        ) {
          if (!this.isTipp) { // User Input from Tipp don't save hier only into Journal 
            this.pathservice.saveUserInput(
              this.pathservice.getStringArrayFromAnswer(this.answerentries)
            );
          }
        } else {
          this.answerentries = this.pathservice.deepcopyAnswerArray(tempArray);
        }
      }
      if (this.templateId === 3) {
        this.processSliderValues();
      }
      if (this.templateId === 6) {
        this.pathservice.loadSpinner = true;
        this.answerentries = this.processCheckboxValues();
      }
      if (this.templateId === 7 || this.templateId === 11) {
        this.isDoubleAnswer = true;
        this.answerentries = this.pathservice.getAnswerFromDoppelAnswer(
          this.answerentriesMulti
        );
      }
      if (this.templateId === 1 || !this.requiredField()) {
        if (!this.isTipp) {
          this.postUserInput();
        }
      } else {
        if (this.templateId === 6 || !this.requiredField()) {
          this.loadStepdto();
        }
        if (this.templateId !== 6 && this.requiredField()) {
          this.answerentries = tempArrayUserAnswers;
        }
      }
    }
    if (this.isTippInitializer) {
      this.requiredField();
      if (this.templateId === 1 && this.stepdto.id === 6) {
        this.answerentries = [new Answer()];
        for (let i = 0; i < this.answerentries.length; i++) {
          if (
            this.answerentries[i].answer === undefined ||
            this.answerentries[i].answer.trim() === ""
          ) {
            this.answerentries.splice(i, 1);
            i--;
          }
        }
      }
      if (this.templateId === 12 && this.tippService.needHelp === 'Ja') {
        this.answerentries = this.wrapStringToAnswerArray(this.translate.instant('sklls.tips.algabzweigungbuttonyes'));
      }
      if (this.templateId === 12 && this.tippService.needHelp === 'Nein') {
        this.answerentries = this.wrapStringToAnswerArray(this.translate.instant('sklls.tips.algabzweigungbuttonno'));
      }
      if (this.templateId === 13 && this.tippService.ichAndere === 'ich') {
        this.answerentries = this.wrapStringToAnswerArray(this.translate.instant('sklls.tips.questionclassno'));
      }
      if (this.templateId === 13 && this.tippService.ichAndere === 'andere') {
        this.answerentries = this.wrapStringToAnswerArray(this.translate.instant('sklls.tips.questionclassyes'));
      }
      if (this.templateId === 14) {
        this.answerentries = this.wrapStringToAnswerArray(this.tippService.kompetenz.wort);
      }
      if (this.templateId === 15) {
        if (this.tippService.tippchoice === 'keiner') {
          this.answerentries = this.wrapStringToAnswerArray(this.translate.instant('sklls.tips.questiontipnone'));
        } else {
          this.answerentries = this.wrapStringToAnswerArray(this.cutIntro(this.tippService.tippchoice.introduction));
        }
      }
    }
    if (this.isTipp) {
      if (this.templateId === 1) {
        this.answerentries = [new Answer()];
        for (let i = 0; i < this.answerentries.length; i++) {
          if (
            this.answerentries[i].answer === undefined ||
            this.answerentries[i].answer.trim() === ""
          ) {
            this.answerentries.splice(i, 1);
            i--;
          }
        } 
      }
      if (this.templateId === 16 && !this.requiredField()) {
        this.answerentries = this.wrapStringToAnswerArray(this.additionalTippText);
      }
      if (this.templateId === 17 && !this.requiredField()) {
        this.answerentries = this.wrapStringToAnswerArray(this.additionalTippText2);
      }
    }
    this.addQuestionsToMessages();
    this.addAnswersToMessages();
    if (this.isTippInitializer) {
      this.initializerNavigator();
    }
    if (this.isTipp) {
        this.tippNavigator();
    }
  }

  /**
   * Add questions to messages array and remove them from coachtexts array.
   */
  addQuestionsToMessages() {
    if (this.required) {
      return;
    }
    this.coachtexts.forEach(element => {
      let message = new Message();
      message.text = element;
      message.id = this.messageId;
      message.type = MessageType.question;
      this.messages.push(message);
      this.messageId++;
    });

    this.coachtexts = [];
  }

  /**
   * Add user answers to messages array.
   */
  addAnswersToMessages() {
    if (this.answerentries.length && !this.required) {
      this.inputActive = false;
      let message = new Message();
      message.id = this.messageId;
      if (this.isDoubleAnswer) {
        this.convertDoubleAnswer();
        this.isDoubleAnswer = false;
      }
      message.answers = this.answerentries;
      message.type = MessageType.answer;
      this.messages.push(message);
      this.messageId++;
    }
  }

  convertDoubleAnswer() {
    this.answerentries[0].answer = this.translate.instant('sklls.coaching.inputimpif') + ' ' + this.answerentries[0].answer;
    this.answerentries[1].answer = this.translate.instant('sklls.coaching.inputimpthen') + ' ' + this.answerentries[1].answer;
  }

  // Checkt ob alle Pflichtfelder ausgefüllt wurden. Return true/false
  requiredField() {
    console.log(this.stepdto.steptypeid);
    this.required = false;
    if (
      this.templateId === 4 ||
      (this.templateId === 6 &&
        this.stepdto.answermin !== this.stepdto.answermax)
    ) {
      if (
        this.answerentries.length < this.stepdto.answermin ||
        this.answerentries.length > this.stepdto.answermax
      ) {
        this.required = true;
      }
    }
    if (
      this.templateId === 2 ||
      (this.templateId === 6 &&
        this.stepdto.answermin === this.stepdto.answermax)
    ) {
      if (this.answerentries.length < this.stepdto.answermin) {
        this.required = true;
      }
    }
    if (this.templateId !== 1 && this.answerentries !== undefined) {
      this.answerentries.forEach(answerentry => {
        if (
          answerentry.answer === undefined ||
          answerentry.answer.trim() === ""
        ) {
          this.required = true;
        }
      });
    }
    if (this.templateId === 7 && this.answerentries !== undefined) {
      if (this.templateId === 7 && this.answerentries !== undefined) {
        let filteredAnswerentries = [];
        this.answerentries.forEach(answerentry => {
          if (
            answerentry.answer !== undefined &&
            answerentry.answer.trim() !== ""
          ) {
            filteredAnswerentries.push(answerentry);
          }
        });

        console.log(filteredAnswerentries.length);

        if (filteredAnswerentries.length !== 2) {
          this.required = true;
        }
      }
    }
    if (this.templateId === 5) {
      if (this.answerentries === undefined || this.answerentries.length === 0) {
        this.required = true;
      } else if (this.answerentries[0].answer.trim() === "") {
        this.required = true;
      } else {
        this.required = false;
      }
    }
    if (this.templateId === 12) {
      if (this.tippService.needHelp === undefined) {
        this.required = true;
      } else {
        this.required = false;
      }
    }
    if (this.templateId === 13) {
      if (this.tippService.ichAndere === undefined) {
        this.required = true;
      } else {
        this.required = false;
      }
    }
    if (this.templateId === 14) {
      if (this.tippService.kompetenz === undefined) {
        this.required = true;
      } else {
        this.required = false;
      }
    }
    if (this.templateId === 15) {
      if (this.tippService.tippchoice === undefined) {
        this.required = true;
      } else {
        this.required = false;
      }
    }
    if (this.templateId === 16) {
      if (this.additionalTippText === undefined) {
        this.required = true;
      } else {
        this.required = false;
      }
    }
    if (this.templateId === 17) {
      if (this.additionalTippText2 === undefined) {
        this.required = true;
      } else {
        this.required = false;
      }
    }
    return this.required;
  }

  initializeTipp() {
    if (this.textalg === undefined) {
      // workaround: not fetching old input
      this.textalg = this.answerentries[0].answer;
    }
    this.pathservice.textalg = this.textalg;
    this.tippDataService.getTipps(this.textalg).subscribe(data => {
      this.pathservice.tipparray = data;
      if (this.pathservice.tipparray.length > 0) {
        this.tippfound = true;
        this.isTippInitializer = true;
        this.initializer();
      } else {
        this.endTimeUserInput();
        this.userInput.inputcontent = JSON.stringify(
          this.pathservice.getStringArrayFromAnswer(this.answerentries)
        );
        this.nextStepdto(this.pathservice.stepdto.id);
      }
    });
  }

  // Läd neuen Step, falls eine StepId hinterlegt ist. (d.h. macht am Pfadende nichts)
  tryGettingStep() {
    if (this.stepdto != null) {
      this.loadStepdto();
    }
  }

  // Load AnswerEntries des Benutzers, wenn die gibt's
  loadAnswerEntries() {
    if (this.stepdto.answerentries != null) {
      if (this.stepdto.steptypeid === 6) {
        this.klickAnswers();
      } else {
        this.answerentries = this.pathservice.wrapStringArraytoAnswer(
          this.stepdto.answerentries
        );
        if (this.stepdto.steptypeid === 9) {
          this.removeTippGefunden();
        }
        if (
          this.stepdto.answervalues != null &&
          this.stepdto.answervalues.length > this.stepdto.answerentries.length
        ) {
          for (
            let i = this.stepdto.answerentries.length;
            i < this.stepdto.answervalues.length;
            i++
          ) {
            this.answerentries.push({ answer: "" });
          }
        }
        if (this.stepdto.answermax > this.stepdto.answerentries.length) {
          for (
            let i = this.stepdto.answerentries.length;
            i <= this.stepdto.answermax;
            i++
          ) {
            this.answerentries.push({ answer: "" });
          }
        }
        if (this.stepdto.steptypeid === 7 || this.stepdto.steptypeid === 11) {
          this.answerentriesMulti = this.pathservice.wrapAnswerArraytoDoppelAnswer(
            this.answerentries
          );
        }
      }
    }
  }

  loadStepdto() {
    this.pathservice.loadSpinner = false;
    this.templateId = this.stepdto.steptypeid;
    if (this.stepdto.skippto !== 0) {
      if (this.stepdto.id === 261 && this.pathservice.pathId === 34) {
        this.skippable261 = true;
      } else {
        this.skippable = true;
      }
    } else {
      this.skippable = false;
      this.skippable261 = false;
    }
    if (this.stepdto.answervalues != null) {
      this.answers = this.pathservice.wrapStringArraytoAnswer(
        this.htmlservice.convertArraytoHtml(this.stepdto.answervalues)
      );
    }
    this.coachtexts = this.pathservice.wrapStringArraytoAnswer(
      this.htmlservice.convertArraytoHtml(this.stepdto.coachtext.split("|"))
    );
    this.startTypingAnimation();
    this.starTimetUserInput();
    if (this.templateId === 7) {
      this.prepareDoppelAnswerArray(this.answerentriesMulti);
    } else {
      this.prepareAnswerArray(this.answerentries);
    }
    if (
      this.stepdto.answervalues == null &&
      (this.stepdto.steptypeid === 4 ||
        this.stepdto.steptypeid === 7 ||
        this.stepdto.steptypeid === 9)
    ) {
      this.prepareDefaultAnswerArray(this.answers);
    } else {
      this.defaultAnswers = false;
    }

    this.loadAnswerEntries();
    this.inputActive = true;
  }

  // Verarbeitet Werte des Templates "Slider" (d.h. setzt Antwort "keine Meinung", wenn Checkbox aktiviert)
  processSliderValues() {
    if (this.sliderDeactivated) {
      this.answerentries[0].answer = "keine Meinung";
    }
  }

  // Verarbeitet Werte des Templates "Checkbox" (d.h. sucht die richtigen Werte zum von den CB zurückgegebenen Bool-Array)
  processCheckboxValues() {
    const processingArray = new Array<Answer>();
    for (let i = 0; i < this.answerentries.length; i++) {
      if (this.answerentries[i].answer) {
        processingArray.push(this.answers[i]);
      }
    }
    return this.pathservice.deepcopyAnswerArray(processingArray);
    // return processingArray;
  }

  // 'klick'  die Antworten, die der User geklickt hat
  // --mögliche Fehler wegen  der Leerzeichen--
  klickAnswers() {
    let answerentries: Answer[] = [new Answer()];
    answerentries = this.pathservice.wrapStringArraytoAnswer(
      this.stepdto.answerentries
    );
    for (let i = 0; i < this.answers.length; i++) {
      for (let j = 0; j < answerentries.length; j++) {
        if (this.answers[i].answer === answerentries[j].answer) {
          this.answerentries[i].answer = answerentries[j].answer;
        }
      }
    }
  }

  newAnswer() {
    this.answerentries.push(new Answer());
  }

  newImplementationAnswer() {
    this.answerentriesMulti.push(new DoppelAnswer());
  }

  removeAnswer() {
    // TODO: 1 durch *Anzahl Pflichtfelder* variable ersetzen
    if (this.answerentries.length !== this.stepdto.answermin) {
      this.answerentries.pop();
    }
  }

  removeImplementationAnswer() {
    if (this.answerentriesMulti.length !== this.stepdto.answermin) {
      this.answerentriesMulti.pop();
    }
  }

  // Return den HilfeText des Steps
  infoText() {
    this.helptext = this.pathservice.wrapStringArraytoAnswer(
      this.htmlservice.convertArraytoHtml(this.stepdto.helptext.split("|"))
    );
    return this.helptext;
  }

  startPath(pathId: number) {
    this.pathservice.setPathId(pathId);
  }

  // Bereitet das Antwort-Array in der richtigen Länge vor.
  prepareAnswerArray(answer: Answer[]) {
    const answerAmount = this.getAnswerAmount();
    for (let i = answer.length; i > answerAmount; i--) {
      answer.pop();
    }
    for (let i = 0; i < answerAmount; i++) {
      if (i < answer.length) {
        answer[i].answer = "";
      } else {
        answer.push({ answer: "" });
      }
    }
  }

  /************* START TIPP INITIALISER ****************************/

  initializer() {
    this.tipp = this.pathservice.getTipp();
    this.tippService.tippArray = this.pathservice.getTippArray();
    this.initializerStepDTO(0);
  }

  initializerStepDTO(stepId: number) {
    const initializerStepsDTO: StepDto[] = [
      {
        id: 0,
        steptypeid: 12,
        coachtext: this.translate.instant('sklls.tips.algabzweigung1').concat('</br>', this.translate.instant('sklls.tips.algabzweigung2')) , 
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 1,
        answermax: 1,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
      {
        id: 1,
        steptypeid: 13,
        coachtext: this.translate.instant('sklls.tips.questionclass1').concat('</br>', this.translate.instant('sklls.tips.questionclass2')) , 
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 1,
        answermax: 1,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
	  {
		id: 11,
		steptypeid: 14,
		coachtext: this.translate.instant('sklls.tips.questioncompetency'),
		helptext: null,
		answervalues: null,
		answerentries: null,
		answermin: 1,
		answermax: 1,
		internalid: 0,
		pathId: null,
		skippto: null,
		pathTitel: '',
		originalStepId: null,
		jumpToPathid: null,
		jumpOut: false
	  },
	  {
	    id: 6,
	    steptypeid: 1,
	    coachtext: '<img src=\"./assets/SALLY_oops.png\" height=\"300px\">'.concat('</br>', this.translate.instant('sklls.tips.missingtip1')) , 
	    helptext: null,
	    answervalues: null,
	    answerentries: null,
	    answermin: 0,
	    answermax: 0,
	    internalid: 0,
	    pathId: null,
	    skippto: null,
	    pathTitel: '',
	    originalStepId: null,
	    jumpToPathid: null,
		jumpOut: false
	  },
	  {
		id: 29,
		steptypeid: 15,
		coachtext: this.translate.instant('sklls.tips.questiontip1').concat('</br>', this.translate.instant('sklls.tips.questiontip2')) , 
		helptext: null,
		answervalues: null,
		answerentries: null,
		answermin: 1,
		answermax: 1,
		internalid: 0,
		pathId: null,
		skippto: null,
		pathTitel: '',
		originalStepId: null,
		jumpToPathid: null,
		jumpOut: false
	  },
    ];
    this.stepdto = initializerStepsDTO.find(i => i.id === stepId);
    this.loadTippInitializerStepdto();
  }


  loadTippInitializerStepdto() {
    this.pathservice.loadSpinner = false;
    this.templateId = this.stepdto.steptypeid;
    this.coachtexts = this.pathservice.wrapStringArraytoAnswer(
      this.htmlservice.convertArraytoHtml(this.stepdto.coachtext.split("|"))
    );
    if (this.stepdto.answervalues == null && this.stepdto.steptypeid === 4) {
      this.prepareDefaultAnswerArray(this.answers);
    } else {
      this.defaultAnswers = false;
    }
    this.startTypingAnimation();
    this.inputActive = true;
  }

  wrapStringToAnswerArray(text) {
    const answerArray = [];
    const answer = new Answer();
    answer.answer = text;
    answerArray.push(answer);
    return answerArray;
  }

  cutIntro(text: string) {
    const titles = text.split('§§');
    return titles[1].toString();
  }

  initializerNavigator() {
    // action if user doesn't want a hint
    if (this.tippService.needHelp === "Nein") {
       this.isTippInitializer = false;
       this.answerentries = [new Answer()];
       this.nextStepdto(this.pathservice.stepdto.id);
       return;
    }
    // action if user want's a hint and there is a 'Fullmatch hint'
    if (
      this.tippService.needHelp === "Ja" &&
      this.tippService.tippArray.length === 1 &&
      this.tippService.tippArray[0].fullmatch === true
    ) {
      const tippchoiced = this.tippService.tippArray[0];
      this.pathservice.setTipp(tippchoiced);
      this.isTippInitializer = false;
      this.isTipp = true;
      this.tippInit();
      return;
    }
    // if no combination is found for User criteria or the user doesn't want one of the found Tipps
    if (this.stepdto.id === 6) {
      if (this.tippService.ichAndere !== undefined) {
        this.tippinputlog.filterclass = this.tippService.ichAndere;
      } else {
        this.tippinputlog.filterclass = "not-Set";
      }
      if (this.tippService.kompetenz !== undefined) {
        this.tippinputlog.filtercompetence = this.tippService.kompetenz.wort;
      } else {
        this.tippinputlog.filtercompetence = "not-set";
      }
      if (this.tippService.tippchoice !== undefined) {
        this.tippinputlog.userchoice = this.tippService.tippchoice;
      } else {
        this.tippinputlog.userchoice = "not-set";
      }
      if (this.textalg !== undefined) {
        this.tippinputlog.text = this.textalg;
      } else {
        this.tippinputlog.text = "null";
      }
      this.tippDataService.saveTippinputlog(this.tippinputlog).subscribe(data => {
        this.isTippInitializer = false;
        this.nextStepdto(this.pathservice.stepdto.id);
        return;
      });
    }
    if (this.tippService.needHelp === "Ja" && this.stepdto.id !== 6) {
      if (this.stepdto.id === 0) {
        this.tippService.verb =
          this.tippService.tippArray[0].verb == undefined
            ? this.tippService.verb
            : this.tippService.tippArray[0].verb;
        this.tippService.noun =
          this.tippService.tippArray[0].noun == undefined
            ? this.tippService.noun
            : this.tippService.tippArray[0].noun;
        this.tippService.verbandnoun[0] = this.tippService.verb;
        this.tippService.verbandnoun[1] = this.tippService.noun;
        this.tippService.previousStep[
          this.tippService.previousStep.length
        ] = this.stepdto.id;
        this.initializerStepDTO(1);
      }
      // if the user decides none of the found hints matches
      if (this.tippService.tippchoice === "keiner") {
        this.tippService.initializerStepHistory.push(this.stepdto.id);
        this.tippService.previousStep[
          this.tippService.previousStep.length
        ] = this.stepdto.id;
        this.initializerStepDTO(6);
        this.tippService.previousStep[
          this.tippService.previousStep.length
        ] = this.stepdto.id;
      }
      // if the user choses one of the displayed hints sets the hint in the pathService and redirects to the hint.
      if (
        this.tippService.tippchoice !== "keiner" &&
        this.tippService.tippchoice !== undefined
      ) {
        this.pathservice.setTipp(this.tippService.tippchoice);
        this.isTippInitializer = false;
        this.isTipp = true;
        this.tippInit();
        return;
      }
      // filters the found hints by 'Class' if there are still more than three
      if (
        (this.tippService.ichAndere === "ich" &&
          this.stepdto.id !== 11 &&
          this.stepdto.id !== 6) ||
        (this.tippService.ichAndere === "andere" &&
          this.stepdto.id !== 11 &&
          this.stepdto.id !== 6)
      ) {
        if (this.tippService.ichAndere !== undefined) {
          this.tippService.initializerData.filterclass = this.tippService.ichAndere;
        } else {
          this.tippService.initializerData.filterclass = "not-Set";
        }
        this.tippDataService
          .filterTippsOne(
            this.tippService.ichAndere,
            this.tippService.verbandnoun
          )
          .subscribe(data => {
            this.tmpTippArray = data;
            this.tippService.tippArray = this.tmpTippArray;
            this.tmpTippArray = new Array<Tipp>();
            this.findUnique();
            this.pathservice.tipparray = this.tippService.tippArray;
            // Sorry pose template if no hint fits
            if (this.tippService.tippArray.length === 0) {
              this.tippService.previousStep[
                this.tippService.previousStep.length
              ] = this.stepdto.id;
              this.initializerStepDTO(6);
            } else if (this.tippService.tippArray.length === 1) {
              const tippchoiced = this.tippService.tippArray[0];
              this.pathservice.setTipp(tippchoiced);
              this.isTippInitializer = false;
              this.isTipp = true;
              this.tippInit();
              return;
            } else {
              // tslint:disable-next-line:no-shadowed-variable
              this.tippDataService
                .getkompetenz(this.tippService.tippArray)
                .subscribe(data => {
                  this.tippService.kompetenzen = data;
                  const keiner = new Kompetenz();
                  keiner.id = 999;
                  keiner.wort = this.translate.instant('sklls.tips.questioncompetencynone');
                  this.tippService.kompetenzen[
                    this.tippService.kompetenzen.length
                  ] = keiner;
                  this.tippService.previousStep[
                    this.tippService.previousStep.length
                  ] = this.stepdto.id;
                  this.skipStep();
                });
            }
          });
      }

      // filters found hints by 'kompetenz' if there are still more than three
      if (this.stepdto.id === 11) {
        if (this.tippService.kompetenz.wort === this.translate.instant('sklls.tips.questioncompetencynone')) {
          this.tippService.initializerStepHistory.push(this.stepdto.id);
          this.tippService.previousStep[
            this.tippService.previousStep.length
          ] = this.stepdto.id;
          this.initializerStepDTO(6);
          this.tippService.previousStep[
            this.tippService.previousStep.length
          ] = this.stepdto.id;
        } else {
          if (this.tippService.kompetenz !== undefined) {
            this.tippService.initializerData.filtercompetence = this.tippService.kompetenz.wort;
          } else {
            this.tippService.initializerData.filtercompetence = "not-set";
          }
          this.tippDataService
            .filterTippsTwo(
              this.tippService.kompetenz.wort.split("/").join(),
              this.tippService.tippArray
            )
            .subscribe(data => {
              this.pathservice.tipparray = this.tippService.tippArray;
              this.tmpTippArray = data;
              this.tippService.tmpTippArrayBack = this.tippService.tippArray;
              this.tippService.tippArray = this.tmpTippArray;
              this.tmpTippArray = new Array<Tipp>();
              // Sorry pose template if no hint fits
              if (this.tippService.tippArray.length === 0) {
                this.tippService.previousStep[
                  this.tippService.previousStep.length
                ] = this.stepdto.id;
                this.initializerStepDTO(6);
              } else if (this.tippService.tippArray.length === 1) {
                // jump straight to Tipp
                this.tippService.previousStep[
                  this.tippService.previousStep.length
                ] = this.stepdto.id;
                const tippchoiced = this.tippService.tippArray[0];
                this.pathservice.setTipp(tippchoiced);
                this.isTippInitializer = false;
                this.isTipp = true;
                this.tippInit();
                return;
              } else if (this.tippService.tippArray.length > 1) {
                // displays the Intro of eache hint and lets the user choose one or none of them
                this.tippService.previousStep[
                  this.tippService.previousStep.length
                ] = this.stepdto.id;
                this.initializerStepDTO(29);
              } else {
                this.tippService.previousStep[
                  this.tippService.previousStep.length
                ] = this.stepdto.id;
                this.initializerStepDTO(6);
              }
            });
        }
      }
    }
    console.log("Momentan bei Step: " + this.stepdto.id);
    this.tippService.initializerStepHistory.push(this.stepdto.id);
  }

  findUnique() {
    if (this.tippService.tippArray.length > 1) {
      this.tippService.tippArray.sort(function(a, b) {
        return a.id - b.id;
      });
      const tippArrayTmp = new Array<Tipp>();
      for (let i = 1; i < this.tippService.tippArray.length; i++) {
        if (
          this.tippService.tippArray[i].id !==
          this.tippService.tippArray[i - 1].id
        ) {
          tippArrayTmp.push(this.tippService.tippArray[i - 1]);
        }
      }
      if (
        this.tippService.tippArray[this.tippService.tippArray.length - 1].id ===
        this.tippService.tippArray[this.tippService.tippArray.length - 2].id
      ) {
        tippArrayTmp.push(
          this.tippService.tippArray[this.tippService.tippArray.length - 1]
        );
      }
      this.tippService.tippArray = [];
      for (let i = 0; i < tippArrayTmp.length; i++) {
        this.tippService.tippArray.push(tippArrayTmp[i]);
      }
    }
  }

  skipStep() {
    if (
      this.stepdto.id === 1 &&
      this.tippService.tippArray.length < 4 &&
      this.tippService.tippArray.length > 1
    ) {
      this.initializerStepDTO(29);
    } else {
      this.initializerStepDTO(11);
    }
  }

  /************* END TIPP INITIALISER ****************************/

  /******************* START TIPP ********************************/

  tippInit() {
    if (this.pathservice.additionalTippText !== undefined && this.pathservice.additionalTippText !== null) {
      let text = this.pathservice.additionalTippText.split('<br><br>');
      this.additionalTippText = text[0];
      if (text.length > 1) {
        this.additionalTippText2 = text[1];
      }
    }
    if (this.pathservice.tippStepNo !== -1) {
      this.stepdto.id = this.pathservice.tippStepNo;
      this.pathservice.tippStepNo = -1;
    }
    this.userInput = this.pathservice.userinputdb;
    this.hint =  this.pathservice.getTipp();
    this.aufgabeTitel = this.appService.title;
    this.title = this.htmlservice.convertToHtml(this.hint.title);
    this.stepdto.id = -1;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(HelpDialogComponent, {
      data: { hilfetext: this.htmlservice.convertToHtml(this.backgroundinfo), title: 'Hintergrundinfo' }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  tippNavigator() {
   if (!this.requiredField()) {
      if (this.stepdto.id === 5 || this.stepdto.id === 6) {
        if (this.additionalTippText2 !== undefined) {
          this.pathservice.setadditionalTippText(this.additionalTippText + '<br><br>' + this.additionalTippText2);
          if (this.stepdto.id === 6) {
            this.displayedTippId = this.hint.id; // is this needed for  the pathservice and/or the journal???
            this.pathservice.tippDisplayed = true;
            this.isTipp = false;
            this.wiederEinstiegAfterTipp();
          } else {
            this.setTippStepdto(this.stepdto.id + 1);
          }
        }
      } else if (this.stepdto.id === 2 && (this.hint.examples == null)) {
        if (this.hint.tryit == null) {
          this.setTippStepdto(5);
        } else {
          this.setTippStepdto(4);
        }
      } else if (this.stepdto.id === 3 && (this.hint.tryit == null)) {
        this.setTippStepdto(5);
      } else if ((this.stepdto.id === 5)) {
        if (this.additionalTippText2 !== undefined) {
          this.setTippStepdto(this.stepdto.id + 1);
        } else {
           this.required = true;
        }
        if (this.additionalTippText2 !== undefined) {
          this.pathservice.setadditionalTippText(this.additionalTippText2);
        }
      } else {
        this.setTippStepdto(this.stepdto.id + 1);
      }
    }
    console.log(this.hint.inputfield);
    console.log(this.textalg);
    console.log(this.stepdto.id);
    this.infobuttonaktiv = false;
  }


  setTippStepdto(stepId: number) {
    const tippStepsDTO: StepDto[] = [
      {
        id: 0,
        steptypeid: 1,
        coachtext: this.hint.introduction,
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 0,
        answermax: 0,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
      {
        id: 1,
        steptypeid: 1,
        coachtext: this.hint.shortdescription,
        helptext: this.hint.moreinformation,
        answervalues: null,
        answerentries: null,
        answermin: 0,
        answermax: 0,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
      {
        id: 2,
        steptypeid: 1,
        coachtext: this.translate.instant('sklls.tips.questionhowitworks').concat('|', this.hint.howitworks),
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 0,
        answermax: 0,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
      {
        id: 3,
        steptypeid: 1,
        coachtext: this.translate.instant('sklls.tips.questionexample').concat('|', this.hint.examples),
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 0,
        answermax: 0,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
      {
        id: 4,
        steptypeid: 16,
        coachtext: this.translate.instant('sklls.tips.questiontryit').concat('|', this.hint.tryit),
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 1,
        answermax: 1,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
      {
        id: 5,
        steptypeid: 17,
        coachtext: this.translate.instant('sklls.tips.questiontransfer1').concat(this.textalg, this.translate.instant('sklls.tips.questiontransfer2')),
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 1,
        answermax: 1,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      },
      {
        id: 6,
        steptypeid: 1,
        coachtext: this.translate.instant('sklls.tips.questionfinish'),
        helptext: null,
        answervalues: null,
        answerentries: null,
        answermin: 0,
        answermax: 0,
        internalid: 0,
        pathId: null,
        skippto: null,
        pathTitel: '',
        originalStepId: null,
        jumpToPathid: null,
		jumpOut: false
      }
    ];
    this.stepdto = tippStepsDTO.find(i => i.id === stepId);
    this.loadTippInitializerStepdto();
  }
  /******************* END TIPP  *********************************/

  prepareDoppelAnswerArray(doppelanswer: DoppelAnswer[]) {
    const answerAmount = this.getAnswerAmount() / 2;
    const emptyrdoppelanswer = new DoppelAnswer();
    emptyrdoppelanswer.answerWenn = "";
    emptyrdoppelanswer.answerDann = "";
    for (let i = doppelanswer.length; i > answerAmount; i--) {
      doppelanswer.pop();
    }
    for (let i = 0; i < answerAmount; i++) {
      if (i < doppelanswer.length) {
        doppelanswer[i] = emptyrdoppelanswer;
      } else {
        doppelanswer.push(emptyrdoppelanswer);
      }
    }
  }

  prepareDefaultAnswerArray(answer: Answer[]) {
    const answerAmount = this.getAnswerAmount();
    this.defaultAnswers = true;
    for (let i = answer.length; i > answerAmount; i--) {
      answer.pop();
    }
    for (let i = 0; i < answerAmount; i++) {
      if (i < answer.length) {
        answer[i].answer = "...";
      } else {
        answer.push({ answer: "..." });
      }
    }
  }

  // Gibt die Länge des Antwort-Array vor.
  getAnswerAmount() {
    if (
      !(
        this.stepdto.answermax === -1 ||
        this.stepdto.steptypeid === 6 ||
        this.stepdto.steptypeid === 2 ||
        this.stepdto.steptypeid === 7
      )
    ) {
      return this.stepdto.answermax;
    } else if (
      this.stepdto.steptypeid === 2 ||
      this.stepdto.steptypeid === 7 ||
      this.stepdto.steptypeid === 11
    ) {
      return this.stepdto.answermin;
    }
    return this.answers.length;
  }

  // Speichert die Nutzereingaben in der DB
  postUserInput() {
    this.endTimeUserInput();
    this.userInput.inputcontent = this.pathservice.removeEscapeCharacter(
      JSON.stringify(
        this.pathservice.getStringArrayFromAnswer(this.answerentries)
      )
    );
    this.userInput.stepid.id = this.pathservice.stepdto.id;
    if (
      this.stepdto.steptypeid === 9 &&
      this.pathservice.tippDisplayed &&
      this.removedTippGefunden
    ) {
      this.addTippGefunden();
    }
    this.stepDataService.addUserInput(this.userInput).subscribe(data => {
      this.userInput.predecessorstepid.id = this.pathservice.stepdto.id;
      this.userInput.predecessorstepid.id = this.pathservice.stepdto.id;
      if (
        (this.templateId === 9) ||
        (this.templateId === 10)
      ) {
        this.initializeTipp();
      } else {
        this.nextStepdto(this.pathservice.stepdto.id);
      }
      this.answerentries = [new Answer()];
    });
  }

  // Remove the additional Statement: 'Tipp gefunden', if exists
  removeTippGefunden() {
    for (let i = 0; i < this.answerentries.length; i++) {
      if (this.answerentries[i].answer.includes("Tipp gefunden")) {
        this.answerentries[i].answer = this.answerentries[i].answer.replace(
          "Tipp gefunden",
          ""
        );
        this.removedTippGefunden = true;
      }
    }
  }

  // Add the additional Statement: 'Tipp gefunden'
  addTippGefunden() {
    this.userInput.inputcontent = this.userInput.inputcontent + "Tipp gefunden";
    this.removedTippGefunden = false;
  }

  preparePostUserInput() {
    this.userInput = new UserInput();
    this.userInput.predecessorstepid = new StepDto();
  }

  // Setzt das startTime eines Templates
  starTimetUserInput() {
    this.userInput.startTime = new Date(Date.now());
  }

  // Setzt das endTime eines Templates
  endTimeUserInput() {
    this.userInput.stepid = new StepDto();
    this.userInput.endTime = new Date(Date.now());
  }

  isQuickChallenge() {
    if (
      this.pathservice.pathType === this.pathservice.reviewStart ||
      this.pathservice.pathType === this.pathservice.prepareStart ||
      this.pathservice.pathType === this.pathservice.celebrateStart ||
      this.pathservice.pathType === this.pathservice.evaluatePos ||
      this.pathservice.pathType === this.pathservice.evaluateNeg ||
      this.pathservice.pathType === this.pathservice.challengeType
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkPlaceHolder() {
    if (this.myplaceHolder) {
      this.myplaceHolder = null;
      return;
    } else {
      this.myplaceHolder = "...";
      return;
    }
  }

  deleteUserEntries() {
    if (this.userInput.stepid !== undefined) {
      this.pathservice.deleteUserInputs(this.userInput.stepid.id);
    } else {
      if (this.isTipp || this.isTippInitializer) {
        this.pathservice.deleteUserInputsByStep(this.pathservice.stepdto.id);
      } else {
        // try delete inputs if there are any
        this.pathservice.deleteUserInputsByStep(this.stepdto.id);
      }
    }
    this.pathservice.additionalTippText = null;
    this.pathservice.tippDisplayed = false;
    this.pathservice.removePathIdFromStack();
    this.mainComponent.changeUserLanguage();
  }
}
