import { JournalEntryDetailsElement } from '../journal-element/journal-entry-details-element';
import { Component, OnInit } from '@angular/core';
import { JournalService } from '../journal/journal-service.service';
import { ProgressService } from '../progress/progress.service';
import { JournalEntry } from '../journal/journal-entry';
import { Router } from '@angular/router';

@Component({
  selector: 'app-journal-entry-editor',
  templateUrl: './journal-entry-editor.component.html',
  styleUrls: ['./journal-entry-editor.component.scss']
})
export class JournalEntryEditorComponent implements OnInit {
  constructor(
    private journalservice: JournalService,
    private progressService: ProgressService,
    public router: Router
  ) {
    if (this.journalservice.getId() === -1) {
      this.journalentry = new JournalEntry();
      this.addDetail();
    } else {
      this.journalentry = this.journalservice.copyEntry(
        this.journalservice.journalentries.filter(
          // tslint:disable-next-line:no-shadowed-variable
          JournalEntry => JournalEntry.id === this.journalservice.getId()
        )[0]
      );
      // tslint:disable-next-line:max-line-length
      // this.journalentrydetailselements =  this.journalservice.copyDetailsArray(this.journalservice.getDetailsForId(this.journalservice.getId()));
    }
    this.journalentrydetailselements = this.journalservice.copyDetailsArray(
      this.journalservice.getDetailsForId(this.journalservice.getId())
    );
  }
  journalentry: JournalEntry;
  journalentrydetailselements = new Array<JournalEntryDetailsElement>();
  journalentrydetailselement = new JournalEntryDetailsElement();

  ngOnInit() {}

  // Fügt ein weiteres Detail an den gerade bearbeiteten Eintrag
  addDetail() {
    const newdetail = new JournalEntryDetailsElement();
    newdetail.journalEntryId = this.journalentry;
    this.journalentrydetailselements.push(newdetail);
  }

  // deprecated - replaced by saveJournal()
  //Speichert im Editor gemachte Änderungen. Wenn es ein neuer Eintrag ist, wird eine neue ID vergeben.
  /*saveChanges(){

    if ( this.journalservice.getId() == -1 ) {
      this.journalentry.createdat = new Date(Date.now());
      this.journalservice.pushNewData(this.journalentrydetailselements, this.journalentry, true)
      this.progressService.addCreatedJournalEntriesToProgress();
    }
    else{
      this.journalservice.pushNewData(this.journalentrydetailselements, this.journalentry, false);

    }
  }*/

  saveJournal() {
    let router = this.router;
    //if (this.journalservice.getId() == -1) {
    //this.journalentry.createdat = new Date(Date.now()); //TODO: Aus Gründen stimmt Datum um einige Tage nicht.
    this.journalservice
      .addJournal(this.journalentrydetailselements, this.journalentry)
      .subscribe(
        data => {
          router.navigate(['main/journal']);
          this.progressService.addCreatedJournalEntriesToProgress();
        },
        error => {
          console.log(error);
        }
      );
    //}
    //else {
    //  this.journalservice.pushNewData(this.journalentrydetailselements, this.journalentry, false);
    //}
  }
}
