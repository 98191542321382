import { Answer } from "./answer-entry";

export class Message {
    id: number;
    type: MessageType;
    text?: Answer;
    answers?: Answer[];
}

export enum MessageType {
    question,
    answer,
}