import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { SecurityService } from '../auth/security-service';
import { TokenStorageService } from '../auth/token-storage.service';
import { UserService } from '../services/user.service';
import { TutorialDialog } from '../tutorial/tutorial.dialog.componenten';
import { AnalyticsInfo } from './analytics-info';
import { AnalyticsService } from './analytics-service';
import { ResilienceService } from '../services/resilience-service';
import { TranslateService } from '@ngx-translate/core';
import { CompetencyResult } from '../resilience-test/competencyResult';
import { Result } from '../resilience-test/result';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class AnalyticsComponent implements OnInit {

  single: any[];
  multi: any[];
  view: any[] = [600, 400];

  // options
  gradient = true;
  showLegend = true;
  showLabels = true;
  isDoughnut = false;
  legendPosition = 'below';
  legend = false;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  showXAxis = true;
  showYAxis = true;
  timeline = true;
  trimYAxisTicks = true;
  maxYAxisTickLength = 42;
  xScaleMin = 0;
  xScaleMax = 8;
  privacy = false;

  colorScheme = {
    domain: ['#062337', '#005f7c', '#ddef03', '#828a86', '#35a0cc', '#c7c8c7', '#6bd4fd']
  };

  ENV = environment;
  form: any = {};
  requestFailed = false;
  requestPassed = false;
  info: any;
  peopleInfo: AnalyticsInfo;
  emailList: string[][];
  errorMessage = '';
  startTime: string;
  endTime: string;
  countWeeks: number;
  countMonth: number;
  numberOfUsersInUserGroup: bigint;
  percentageOfUsersInUserGroupWhoHaveStartedAtLeastOneCoachingSession: number;
  numberOfStartedCoachingsInThePeriodForTheEntireUserGroup: bigint;
  numberOfCoachingsPerActiveUser: number;
  numberOfCoachingsInThePeriod: number;
  public lineChartMonth: any;
  public lineChartWeek: any;
  public pieChart: any;
  public firstBarChart: any;
  public secondBarChart: any;
  public thirdBarChart: any;
  isShown = false ;
  isAuthorized = false;
  enoughData = false;
  userlanguage: string;
  requestedLanguage: string;
  showMobilLogo: boolean = false;
  result: any;
  options: any;
  spiderData: any;
  // TODO add more colors?
  colorFirst: string = 'rgb(221, 239, 3)';
  colorLatest: string = 'rgb(6, 35, 55)'
  riskText = new Array<any>();
  strengthText = new Array<any>();
  potentialText = new Array<any>();
  public firstResilienceResults: any;
  public latestResilienceResults: any;
  horizontalBarData: any;
  horizontalOptions: any;
  public totalResilienceScoreOfCompany: any;


  constructor(private analyticsService: AnalyticsService,
              private router: Router,
              private tokenStorage: TokenStorageService,
              private dialog: MatDialog,
              private userService: UserService,
              private appService: AppService,
              private securityService: SecurityService,
              private resilienceService: ResilienceService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.analyticsService.get().subscribe(
      data => {
        this.isAuthorized = data;
      },
      error => {
        this.errorMessage = error.error.message;
      }
    )
    this.userService.getUserPrivacy().subscribe((data) => {
      this.privacy = data;
      this.checkPrivacy();
    });
    this.info = {
      token: this.tokenStorage.getToken() || this.tokenStorage.getSessionToken(),
      fullname: this.tokenStorage.getUsername() || this.tokenStorage.getSessionUsername(),
      user: this.securityService.hasUserRole(),
      admin: this.securityService.hasAdminRole(),
      resilienceModel: this.securityService.hasResilienceModel()
    };

    this.userService.getUserLanguage().subscribe(
      data => {
        this.userlanguage = data;
        if (this.appService.selectedLanguage !== this.userlanguage) {
          this.appService.changeLanguage(this.userlanguage);
        }
      }
    );
  }

  onSubmit() {

    this.peopleInfo = new AnalyticsInfo(this.form.startTime, this.form.endTime);
    this.analyticsService.post(this.peopleInfo).subscribe(
      data => {
        console.log(data);
        this.countWeeks = (data as any).countWeeks;
        this.countMonth = (data as any).countMonth;
        this.numberOfUsersInUserGroup = (data as any).numberOfUsersInUserGroup;
        this.percentageOfUsersInUserGroupWhoHaveStartedAtLeastOneCoachingSession = (data as any).percentageOfUsersInUserGroupWhoHaveStartedAtLeastOneCoachingSession;
        this.numberOfStartedCoachingsInThePeriodForTheEntireUserGroup = (data as any).numberOfStartedCoachingsInThePeriodForTheEntireUserGroup;
        this.numberOfCoachingsPerActiveUser = (data as any).numberOfCoachingsPerActiveUser;
        this.numberOfCoachingsInThePeriod = (data as any).numberOfCoachingsInThePeriod;
        this.firstBarChart = (data as any).firstBarChart;
        this.secondBarChart = (data as any).secondBarChart;
        this.pieChart = (data as any).pieChart;
        this.thirdBarChart = (data as any).thirdBarChart;
        this.lineChartWeek = (data as any).lineChartWeek;
        this.firstResilienceResults = (data as any).firstResilienceResults;
        this.latestResilienceResults = (data as any).latestResilienceResults;
        this.totalResilienceScoreOfCompany = (data as any).totalResilienceScoreOfCompany;
        this.isAuthorized = (data as any).authorized;
        this.enoughData = (data as any).enoughData;
        this.requestFailed = false;
        this.requestPassed = true;
        console.log(this.numberOfCoachingsInThePeriod);
        this.resilienceService.getResilienceCompetencies().subscribe(
          testResults => {
            this.result = testResults;
            this.spiderData = {
              // labels are all the same so we take the first test results labels, they are ordered in BE my competencyId, so they are always in same order
              labels: this.createLabels(this.result), 
              datasets: [ 
                {
                label: this.translate.instant('sklls.resilience.firstResults'),
                borderColor: this.colorFirst,
                pointBackgroundColor: this.colorFirst,
                pointBorderColor: this.colorFirst,
                pointHoverBackgroundColor: this.colorFirst,
                pointHoverBorderColor: this.colorFirst,
                data: this.firstResilienceResults
              }, {
                label: this.translate.instant('sklls.resilience.latestResults'),
                borderColor: this.colorLatest,
                pointBackgroundColor: this.colorLatest,
                pointBorderColor: this.colorLatest,
                pointHoverBackgroundColor: this.colorLatest,
                pointHoverBorderColor: this.colorLatest,
                data: this.latestResilienceResults
              }
            ]
            }
            this.options = {
              legend: {
                position: 'bottom'
             },
              scale: {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  // possibleMaxResult are all the same so we can take the first one
                  max: 15,
                  stepSize: 5
                },
                pointLabels: {
                  fontSize: 12,
                  fontColor: 'rgb(6, 35, 55)',
                  callback: function(label) {
                    if (/\s/.test(label)) {
                      return label.split(" ");
                    } else {
                      return label;
                    }              
                  }
              },
              legend: {
                labels: {
                  fontFamily: 'Roboto',
                  fontSize: 15
                }
             },
               gridLines: {
                color: 'rgb(6, 35, 55)'
               },
               angleLines: {
                color: 'rgb(6, 35, 55)'
               }
              }
            }
          }
        )
        this.horizontalBarData = {
          labels: [this.translate.instant('sklls.analytics.resiliencepercentage')],
          datasets: [
              {
                label: this.translate.instant('sklls.resilience.totalScore'),
                backgroundColor: ['#062337'],
                data: this.totalResilienceScoreOfCompany
              }
    
          ]
      };
    
      this.horizontalOptions = {
          indexAxis: 'y',
          plugins: {
              legend: {
                  labels: {
                      color: '#black'
                  }
              }
          },legend: {
          position: 'bottom'
       },
          scales: {
              xAxes: [{
                  ticks: {
                  color: '#black',
                  min: 0,
                  max: 100,
                  stepSize: 10
                  }
              }],
              yAxes: {
                  ticks: {
                      color: '#black',
                  }
              }
          }
      };
      },
      error => {
        console.log(error);
        this.errorMessage = error.error.message;
        this.requestFailed = true;
      });
  }

  reloadPage() {
    window.location.reload();
  }
  format(data) {
    return data + '%';
  }

  toggleShow() {
    this.isShown = true;
    }

    logout() {
      setTimeout(() => {}, 1500);
      this.tokenStorage.signOut();
      this.tokenStorage.signSessionOut();
      window.location.reload();
    }

    checkPrivacy() {
      if (!this.privacy) {
        this.goToPrivacy();
      }
    }
  
    goMain() {
      this.router.navigateByUrl("/main/journey");
    }

    goToPrivacy() {
      this.router.navigateByUrl("/login/privacy");
    }

    requestChangeUserLanguage(lang) {
      this.requestedLanguage = lang;
        this.changeUserLanguage();
    }

    changeUserLanguage() {
      if (this.requestedLanguage !== undefined && this.appService.selectedLanguage !== this.requestedLanguage) {
        this.userService
          .setUserLanguage(this.requestedLanguage)
          .subscribe((data) => {
            this.userlanguage = data;
            this.appService.changeLanguage(data);
            this.requestedLanguage = null;
            window.location.reload();
          });
      }
    }

    createLabels(result: any) {
      const labels = new Array<string>();
      for (let i = 0; i < result.length; i++) {
        labels.push(result[i].second);
      }
      return labels;
    }
}
