import {ResetPasswordInfo} from './resetPassword-info';
import {ResetPasswordService} from './resetPassword-service';
import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from '../auth/token-storage.service';
import {PathService} from '../messenger/path.service';
import {environment} from 'src/environments/environment';
import {CustomTranslationLoader} from '../translate/translate.service';
import {AppService} from '../app.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: "app-login",
  templateUrl: "./resetPassword.component.html",
  styleUrls: ["./resetPassword.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  ENV = environment;
  form: any = {};
  passwordsNotEqual = false;
  errorMessage = "";
  private resetPasswordInfo: ResetPasswordInfo;
  hasError = false;
  badRequest = false;
  isSuccessful = false;
  token = "";

  constructor(
    private tokenStorage: TokenStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public customTranslationLoader: CustomTranslationLoader,
    private appService: AppService,
    private resetPasswordService: ResetPasswordService
  ) {}

  ngOnInit() {
     this.activatedRoute.queryParams.subscribe(params => {
        this.token = params['token'];
     })
  }

  onSubmit() {
    this.passwordsNotEqual = false;
    this.hasError = false;
    this.badRequest = false;
    this.isSuccessful = false;
    
    if (this.form.password === this.form.passwordAgain) {
      this.resetPasswordInfo = new ResetPasswordInfo(this.form.password, this.token);
      this.resetPasswordService.resetPassword(this.resetPasswordInfo).subscribe(
        data => {
          this.isSuccessful = true;
          // redirect to login after a short pause (to display success message)
          setTimeout(() => {
            this.router.navigateByUrl('/login');
          }, 3000);
        },
        error => {
          if (error.status == 400) {
            this.badRequest = true;
          } else {
            this.hasError = true;
          }
        }
      );
    } else {
      this.passwordsNotEqual = true;
    }
  }

  onChangeLanguage(lang) {
    this.tokenStorage.setLanguange(lang);
    this.tokenStorage.setSessionLanguange(lang);
    this.appService.changeLanguage(lang);
  }
}
