import { HtmlService } from '../html.service';
import { Injectable } from '@angular/core';
import { JournalEntryDetailsElement } from '../journal-element/journal-entry-details-element';
import { JournalEntry } from './journal-entry';
import { JournalDataService } from './journal.data.service';
import { Observable } from 'rxjs';
import { JournalDetailsDataService } from '../journal-element/Journal.Details.data.service';
import { JournalEntryDto } from './journalEntryDto';

@Injectable({
  providedIn: 'root'
})
export class JournalService {
  private favoritefiltered: boolean;
  private entry_id: number;

  constructor(
    private journaldataservice: JournalDataService,
    private journaldetailsdataservice: JournalDetailsDataService,
    private htmlService: HtmlService
  ) {}
  journalentrydetailselements: JournalEntryDetailsElement[];
  journalentries: JournalEntry[];
  journalentriesall: JournalEntryDto[];
  journalentriesDto: JournalEntryDto[];
  maxid: number;

  getJournalEntries() {
    const journalentries = new Array<JournalEntry>();
    return this.journaldataservice.getJournals().subscribe(data => {
      this.journalentriesDto = data;
      this.journalentriesDto.forEach(dto => {
        journalentries.push(dto.journalEntry);
      });
      this.journalentries = journalentries;
      this.journalentriesall = data;
    });
  }

  updateJournalEntries(journal: JournalEntry) {
    return this.journaldataservice
      .updateJournal(journal)
      .subscribe(data => (journal = data));
  }

  addJournalEntry(
    journal: JournalEntry,
    details: JournalEntryDetailsElement[]
  ): Observable<any> {
    return this.journaldataservice.saveJournal(journal, details);
  }

  getJournalDetails() {
    return this.journaldetailsdataservice.getEntryDetails().subscribe(data => {
      this.journalentrydetailselements = data;
      this.convertTextToHtml(data);
    });
  }

  // convert JournalEntryDetailsElementeText to Html
  convertTextToHtml(journalEntryDetailsElemente: JournalEntryDetailsElement[]) {
    journalEntryDetailsElemente.forEach(detail => {
      if (detail.text !== null) {
        detail.text = this.htmlService.convertToHtml(detail.text);
      }
    });
  }

  addJournalDetails(journalDetails: JournalEntryDetailsElement) {
    return this.journaldetailsdataservice
      .addDetails(journalDetails)
      .subscribe(data => (journalDetails = data));
  }

  updateJournalDetails(journalDetails: JournalEntryDetailsElement) {
    return this.journaldetailsdataservice
      .updateDetails(journalDetails)
      .subscribe(data => (journalDetails = data));
  }

  getJournalDetail(journalDetailId: number) {
    return this.journaldetailsdataservice
      .getJournalEntryDetail(journalDetailId)
      .subscribe();
  }

  invertBoolean(bool: boolean) {
    if (bool === false) {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  }

  setFavoriteFilter(bool: boolean) {
    this.favoritefiltered = bool;
  }

  getFavoriteFilter() {
    return this.favoritefiltered;
  }

  // Sucht alle Details, welche der ID eines Journaleintrags zugeordnet sind
  getDetailsForId(id: number) {
    return this.journalentrydetailselements.filter(
      // tslint:disable-next-line:no-shadowed-variable
      JournalEntryDetailsElement =>
        JournalEntryDetailsElement.journalEntryId.id === id
    );
  }

  // Setzt ID im Service. Wichtig für das Laden des Editors
  setId(id: number) {
    this.entry_id = id;
  }

  getId() {
    return this.entry_id;
  }

  // Gibt neue ID für neuen Journaleintrag zurück
  getNewId() {
    // TODO: ID von gerade erzeugtem Journaleintrag zurückgeben.
    // Momentan wird die id des letzten eintrags gesucht. Funtkioniert aber nur solange es nur einen Benutzer gibt.
    // TODO: Lösung für mehrere User finden.
    // return ((this.journalentries[this.journalentries.length-1].id) +1);
  }

  prepareEditor(id: number) {
    this.setId(id);
  }

  // -1 ist die ID des Template
  createNewJournalEntry() {
    this.setId(-1);
  }

  // Speichert die Daten aus dem Editor in die allgemeinen Arrays im Journalservice.
  addJournal(
    editedDetails: JournalEntryDetailsElement[],
    editedEntry: JournalEntry
  ): Observable<any> {
    // this.addJournalEntry(editedEntry, editedDetails);
    return this.addJournalEntry(editedEntry, editedDetails);
  }

  // deprecated - replaced by addJournal()
  // Speichert die Daten aus dem Editor in die allgemeinen Arrays im Journalservice.
  /*pushNewData(editedDetails: JournalEntryDetailsElement[], editedEntry: JournalEntry, newEntry:boolean){
    let found = false;
    if (newEntry) {
      this.addJournalEntry(editedEntry, editedDetails);

    }else{
      for(let i = 0; i<this.journalentries.length; i++){
        if(this.journalentries[i].id === editedEntry.id)
        {this.journalentries[i].title = editedEntry.title;
          break;
        }
      }
      editedDetails.forEach(element => {
        found = false;
        for (let i = 0; i < this.journalentrydetailselements.length; i++) {
          if (this.journalentrydetailselements[i].journalEntryId.id === element.journalEntryId.id && this.journalentrydetailselements[i].id === element.id){
            this.journalentrydetailselements[i].title = element.title;
            this.journalentrydetailselements[i].text = element.text;
            found = true;
            this.updateJournalDetails(element);
            break;
          }
        }
        if (!found) {
          this.addJournalDetails(element);
         }
      });
    }

  }*/

  // Diese drei Methoden kopieren die einzelnen Attribute der Objekte, um CallbyValue zu erzwingen. (für Editor)
  // TODO: Allgemein halten, falls Objekte sich verändern.
  copyEntry(entry: JournalEntry) {
    let entryCopy = new JournalEntry();
    entryCopy = entry;
    return entryCopy;
  }

  copyDetailsArray(details: JournalEntryDetailsElement[]) {
    const detailsCopy = new Array<JournalEntryDetailsElement>();
    for (let i = 0; i < details.length; i++) {
      detailsCopy.push(this.copyDetail(details[i]));
    }
    return detailsCopy;
  }

  copyDetail(detail: JournalEntryDetailsElement) {
    let detailCopy = new JournalEntryDetailsElement();
    detailCopy = detail;
    return detailCopy;
  }

  /*search(term: string){
  }*/

}
