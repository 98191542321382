import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Result } from "../resilience-test/result";
import { CompetencyResult } from "../resilience-test/competencyResult";

@Injectable({
    providedIn: 'root',
  })
  export class ResilienceService {

    private apiUrl = environment.apiUrl + 'account/resilienceResult';
    private competencyUrl = environment.apiUrl + 'account/resilienceCompetencies';
    private totalScoreUrl = environment.apiUrl + 'account/totalScoreResilienceResultsOfSingleUser';
  
    constructor(private http: HttpClient) {
    }

    getResilienceResult(): Observable<Result[]> {
      return this.http.get<Result[]>(this.apiUrl);
    }

    getResilienceCompetencies(): Observable<CompetencyResult[]> {
      return this.http.get<CompetencyResult[]>(this.competencyUrl);
    }

    getTotalScoreResilienceResults(): Observable<number> {
      return this.http.get<number>(this.totalScoreUrl);
    }
}