import { HtmlService } from './../html.service';
import { ProgressService } from './../progress/progress.service';
import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-popup-widget',
  templateUrl: './popup-widget.component.html',
  styleUrls: ['./popup-widget.component.scss']
})
export class PopupWidgetComponent implements OnInit {


  constructor( private htmlService: HtmlService,   public dialog: MatDialog,
    public dialogRef: MatDialogRef<PopupWidgetComponent>,
    private progressService: ProgressService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
